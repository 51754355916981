import React, { useEffect, useState } from "react";
import { useOverrides, Override } from "@quarkly/components";

import { AiOutlineMenu } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllMenu } from "redux/Menu/menu-actions";
import { setLoading } from "redux/Menu/menu-actions";
import { Image, LinkBox, Text, Box, Icon, Section } from "@quarkly/widgets";
const defaultProps = {
  "justify-content": "center",
  padding: "10px 0 24px0 0",
  width: "auto",
};

const Nav = (props) => {
  const { children, rest } = useOverrides(
    props,

    defaultProps
  );
  // eslint-disable-next-line
  const [navbar, setnavbar] = useState(0);
  // const setclick = () => setnavbar(false);

  const dispatch = useDispatch();

  // let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let brandDetails = useSelector((state) => state.brandDetails);

  useEffect(() => {
    // console.log("Header");
    async function mount() {
      await dispatch(setLoading(true));
      await dispatch(getAllMenu());

      const timer = setTimeout(async () => {
        await dispatch(setLoading(false));
      }, 2000);

      return () => clearTimeout(timer);
    }
    mount();
  }, [dispatch]);

  // console.log(data, "menu");

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  function applyFontFamily() {
    if (brandDetails && brandDetails.hasOwnProperty("primaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.primaryFont
      );
    // console.log(applyFontFamily.primaryFont, "font");
    if (brandDetails && brandDetails.hasOwnProperty("secondaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.secondaryFont
      );

    if (brandDetails && brandDetails.hasOwnProperty("tertiaryColor"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.tertiaryColor
      );
  }
  applyFontFamily();

  return (
    <Section {...rest} margin="4rem 0px 0px 0px">
      <Override
        slot="SectionContent"
        margin="0px atuo 0px auto"
        display="flex"
        flex-direction="row"
        align-items="center"
        justify-content="space-between"
      />
      <LinkBox md-margin="0px 20px 0px 0px" href="/home">
        <Image
          display="block"
          width="160px"
          height="90px"
          object-fit="contain"
          sm-width="140px"
          sm-height="70px"
          md-height="80px"
          src={getImages(brandProfile)}
          object-position="50% 0%"
          alt="logo"
        />
      </LinkBox>
      <Box display="flex" sm-display="none">
        <LinkBox margin="0px 38px 0px 0px" href="/home" color="--primary">
          <Text
            margin="0px 0px 0px 0px"
            font="--subheader1"
            color="--grey"
            children="Home"
            hover-color="--primary"
          />
        </LinkBox>
        <LinkBox
          margin="0px 38px 0px 0px"
          href="/about"
          hover-color="--primary"
        >
          <Text
            color="--grey"
            margin="0px 0px 0px 0px"
            font="--subheader1"
            children="Studio"
            hover-color="--primary"
          />
        </LinkBox>
        <LinkBox
          margin="0px 38px 0px 0px"
          href="/product"
          hover-color="--primary"
        >
          <Text
            color="--grey"
            margin="0px 0px 0px 0px"
            font="--subheader1"
            children="Product"
            hover-color="--primary"
          />
        </LinkBox>
        <LinkBox href="/contact">
          <Text
            color="--grey"
            margin="0px 0px 0px 0px"
            font="--subheader1"
            children="Contact us"
            hover-color="--primary"
          />
        </LinkBox>
      </Box>
      <Box display="none" sm-display="block">
        <Icon
          category="ai"
          icon={AiOutlineMenu}
          size="32px"
          color="#b5b5b5"
          sm-color="--primary"
        />
      </Box>
      {children}
    </Section>
  );
};

Object.assign(Nav, { ...Section, defaultProps });
export default Nav;
