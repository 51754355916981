import React, { useEffect, useState } from "react";
import { useOverrides, Override } from "@quarkly/components";
import {
  Image,
  LinkBox,
  Link,
  Box,
  Icon,
  Text,
  Section,
} from "@quarkly/widgets";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { getCms, setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useDispatch, useSelector } from "react-redux/es/exports";
// import { useParams } from "react-router-dom";

const defaultProps = {
  padding: "80px 0 30px 0",
  "quarkly-title": "Footer-16",
  "sm-padding": "40px 0 10px 0",
};

const Footer = (props) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  // const BrandDetails = useSelector((state) => state.brandDetails);

  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["Footer"]);
  let cmsStatus = useSelector((state) => state.cms.cmsActive);

  //-----------hide/show-------

  //----------------hide/show-----------

  useEffect(() => {
    // console.log(data, "Footer");

    async function mount() {
      await dispatch(getCms());
    }
    mount();
    setMounted(true);
  }, [setMounted, dispatch]);
  // console.log(data, "outside");

  // const { token } = useParams();

  if (cmsStatus) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStylefooter");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }

  async function isSideBarOpen(id) {
    // console.log(cmsStatus, "CMSDATA");
    // if (cmsStatus) {
    await updateSelectedData(id);
    // selectedData = id;
    setIsShow(true);

    console.log(isShow, selectedData, id, "show");
    // }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }
  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  // function getFavicon(value) {
  //   return value
  //     ? value.favIcon
  //       ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
  //       : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
  //     : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  // }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  const { children, rest } = useOverrides(props, defaultProps);
  return (
    <div>
      <Section {...rest}>
        <Override slot="SectionContent" />
        <Box
          display="flex"
          margin="0px 0px 50px 0px"
          md-margin="0px 0px 45px 0px"
          sm-margin="0px 0px 15px 0px"
          lg-flex-direction="row"
          lg-width="fit-content"
          lg-align-items="center"
          lg-justify-content="center"
          sm-flex-direction="column"
          sm-align-items="flex-start"
        >
          <LinkBox
            width="20%"
            href="/home"
            sm-align-items="center"
            lg-width="fit-content"
            lg-margin="0px 60px 0px 0px"
            sm-width="max-content"
            sm-margin="auto"
          >
            <Image
              display="block"
              align-self="flex-start"
              width="200px"
              lg-width="150px"
              src={getImages(brandProfile)}
              object-position="50% 0%"
              alt="logo"
            />
          </LinkBox>
          <Box
            display="flex"
            sm-display="block"
            width="80%"
            justify-content="flex-end"
            align-items="center"
            md-flex-direction="column"
            md-align-items="flex-start"
            md-display="flex"
            md-grid-gap="24px"
            lg-width="fit-content"
          >
            <Box
              align-items="flex-start"
              margin="0px 50px 0px 10px"
              lg-align-items="flex-start"
              justify-content="center"
              display="flex"
              flex-direction="row"
              flex-wrap="wrap"
              md-justify-content="flex-start"
              md-margin="0px 50px 0px 0px"
              lg-margin="0px 30px 0px 0px"
              lg-width="400px"
              sm-align-items="center"
              sm-justify-items="center"
              sm-width="100%"
              sm-margin="1rem 0px 1rem 0px"
              className="lg:gap-8 md:gap-6 gap-x-3 gap-y-2"
            >
              <Link
                border-color="--color-primary"
                display="flex"
                font="normal 400 16px/24px --fontFamily-googleRoboto"
                margin="0px 0 0px 0"
                lg-border-width="0px 0px 0px 2px"
                href="/home"
                text-decoration-line="initial"
                color="--darkL2"
                letter-spacing="1px"
                md-margin="0px 0 0px 0"
                lg-margin="0px 0px 0px 0px"
                lg-width="fit-content"
                lg-font="--descirption3"
                lg-text-align="left"
                hover-color="--primary"
              >
                HOME
              </Link>
              <Link
                margin="0px 0 0px 0"
                display="flex"
                href="/about us"
                font="normal 400 16px/24px --fontFamily-googleRoboto"
                text-decoration-line="initial"
                color="--darkL2"
                letter-spacing="1px"
                md-margin="0px 0 0px 0"
                lg-width="fit-content"
                lg-font="--descirption3"
                hover-color="--primary"
              >
                STUDIO
              </Link>
              <Link
                margin="0px 0 0px 0"
                href="/gallery"
                text-decoration-line="initial"
                color="--darkL2"
                font="normal 400 16px/24px --fontFamily-googleRoboto"
                display="flex"
                letter-spacing="1px"
                md-margin="0px 0 0px 0"
                lg-width="fit-content"
                lg-margin="0px 0px 0px 0px"
                lg-font="--descirption3"
                hover-color="--primary"
              >
                PORTFOLIO
              </Link>
              <Link
                margin="0px 0 0px 0"
                href="/contact us"
                font="normal 400 16px/24px --fontFamily-googleRoboto"
                text-decoration-line="initial"
                color="--darkL2"
                display="flex"
                letter-spacing="1px"
                md-margin="0px 0 0px 0"
                lg-display="grid"
                lg-font="--descirption3"
                lg-width="fit-content"
                hover-color="--primary"
              >
                CONTACT US
              </Link>
            </Box>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              grid-template-columns="repeat(5, 1fr)"
              grid-gap="16px 24px"
              md-align-self="flex-start"
              justify-items="start"
              justify-content="center"
              lg-justify-content="flex-end"
              sm-width="100%"
              sm-justify-content="center"
            >
              <LinkBox
                href="https://m.facebook.com/JWRArch"
                aria-label="FaFacebook"
                target="_blank"
                color="--darkL2"
                sm-align-items="center"
              >
                <Icon
                  category="fa"
                  icon={FaFacebook}
                  size="24px"
                  color="--darkL2"
                  hover-color="--primary"
                  transition="background-color 1s ease 0s"
                />
              </LinkBox>
              <LinkBox
                href="https://instagram.com/jwra_architects?igshid=YzAwZjE1ZTI0Zg=="
                aria-label="FaInstagram"
                color="--darkL2"
                target="_blank"
                sm-align-items="center"
              >
                <Icon
                  category="fa"
                  icon={FaInstagram}
                  size="24px"
                  color="--darkL2"
                  hover-color="--primary"
                  transition="background-color 1s ease 0s"
                />
              </LinkBox>
              {/* <LinkBox
              href="/www.twitter.com"
              color="--darkL2"
              sm-align-items="center"
            >
              <Icon
                category="fa"
                icon={FaTwitterSquare}
                size="24px"
                color="--darkL2"
                hover-color="--primary"
                transition="background-color 1s ease 0s"
              />
            </LinkBox> */}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          md-flex-direction="column"
          md-align-items="flex-start"
          justify-content="space-between"
          padding="30px 0px 0px 0px"
          border-width="1px 0 0 0"
          border-style="solid"
          border-color="#2c3339"
          align-items="flex-start"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            lg-width="100%"
            md-width="100%"
            lg-margin="0px 0px 20px 0px"
            sm-flex-direction="column"
          >
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 50px 0px 0px"
              display="flex"
              flex-direction="column"
            >
              <Text
                margin="0px 0px 25px 0px"
                md-margin="0px 0px 20px 0px"
                letter-spacing="1px"
                color="--darkL2"
                font="--description2"
                sm-font="--subheader4"
                id="3645606729"
                className="hoverText"
                onClick={() => isSideBarOpen("3645606729")}
              >
                {data ? data["3645606729"] : "wdwd"}
              </Text>
              <Box id="4733406880" className="hoverText">
                <Link
                  href="tel:+91 89762 82122"
                  text-decoration-line="initial"
                  display="inline-block"
                  text-align="center"
                  margin="0px 0px 5px 0px"
                  hover-color="white"
                  sm-color="--darkL2"
                  font="--descirption3"
                  width="max-content"
                  color="--grey"
                  className="hover"
                  onClick={() => isSideBarOpen("4733406880")}
                  children={
                    <>{data ? data["4733406880"] : "+91 89762 82122"}</>
                  }
                />
              </Box>
              <Box id="7327516953" className="hoverText">
                <Link
                  href="tel:+91 79775 01255"
                  text-decoration-line="initial"
                  display="inline-block"
                  text-align="center"
                  margin="0px 0px 5px 0px"
                  hover-color="white"
                  sm-color="--darkL2"
                  font="--descirption3"
                  width="max-content"
                  color="--grey"
                  className="hover"
                  onClick={() => isSideBarOpen("7327516953")}
                  children={
                    <>{data ? data["7327516953"] : "+91 79775 01255"}</>
                  }
                />
              </Box>
              <Link
                href="mailto:info@yourdomain.com"
                text-decoration-line="initial"
                display="inline-block"
                margin="0px 0px 15px 0px"
                sm-margin="0px 0px 35px 0px"
                hover-color="white"
                sm-color="--darkL2"
                sm-font="--descirption3"
                font="--descirption3"
                text-align="left"
                color="--grey"
                id="5155073268"
                className="hoverText hover"
                onClick={() => isSideBarOpen("5155073268")}
              >
                {data ? data["5155073268"] : "jeff@jwra.in"}
              </Link>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              width="50%"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 25px 0px"
                md-margin="0px 0px 20px 0px"
                letter-spacing="1px"
                md-color="--darkL2"
                color="--darkL1"
                font="--description2"
                sm-font="--subheader4"
                id="4214393830"
                className="hoverText"
                onClick={() => isSideBarOpen("4214393830")}
              >
                {data ? data["4214393830"] : "ADDRESS"}
              </Text>
              <Text
                margin="0px 0px 20px 0px"
                border-color="#b8acac"
                md-margin="0px 0px 15px 0px"
                sm-color="--darkL2"
                sm-font="--descirption3"
                font="--descirption3"
                color="--grey"
                id="1969035199"
                className="hoverText"
                onClick={() => isSideBarOpen("1969035199")}
              >
                {data
                  ? data["1969035199"]
                  : " 1204, Venus towers, Veera Desai Andheri West. 400053."}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            lg-display="flex"
            md-align-self="stretch"
            sm-flex-direction="column-reverse"
            md-width="100%"
            lg-flex-direction="row-reverse"
            lg-width="100%"
            lg-justify-content="space-between"
          >
            <Text
              font="--base"
              md-margin="0px 0px 0 0px"
              md-width="100%"
              margin="0 30px 0 0px"
              sm-color="--darkL2"
              sm-font="--descirption3"
              sm-padding="0 0 0px 0"
              sm-margin="10px 0 0px 0"
              color="--darkL1"
              id="8728180759"
              sm-text-align="center"
              className="hoverText "
              onClick={() => isSideBarOpen("8728180759")}
            >
              {data ? data["8728180759"] : "© 2023 Company, Inc."}
            </Text>
            <div className="flex justify-between gap-4">
              <Box
                margin="0px"
                id="6362173157"
                className="hoverText"
                onClick={() => isSideBarOpen("6362173157")}
              >
                <Link
                  padding="0 0 0 0"
                  margin="0px 0px 0px 0"
                  lg-margin="0px 6px 0px 0px"
                  display="block"
                  font="--base"
                  text-decoration-line="initial"
                  sm-padding="0 0 15px 0"
                  md-white-space="nowrap"
                  sm-order="-1"
                  sm-color="--darkL2"
                  sm-font="--descirption3"
                  color="--darkL1"
                  href="/terms-conditions"
                  hover-color="--primary"
                >
                  {data ? data["6362173157"] : "Terms & Condition"}
                </Link>
              </Box>

              <Box
                margin="0px"
                id="8305761925"
                className="hoverText"
                onClick={() => isSideBarOpen("8305761925")}
              >
                <Link
                  padding="0 0 0 0"
                  margin="0px 0 0px 0"
                  lg-margin="0px 6px 0px 0px"
                  display="block"
                  font="--base"
                  text-decoration-line="initial"
                  sm-padding="0 0 15px 0"
                  md-white-space="nowrap"
                  sm-order="-1"
                  sm-color="--darkL2"
                  sm-font="--descirption3"
                  color="--darkL1"
                  href="/policy"
                  hover-color="--primary"
                >
                  {data ? data["8305761925"] : "Privacy Policy"}
                </Link>
              </Box>
            </div>
          </Box>
        </Box>
        {children}
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
    </div>
  );
};

Object.assign(Footer, { ...Section, defaultProps });
export default Footer;
