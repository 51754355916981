import React from "react";
import theme from "theme";
import { Theme, Text, Icon, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { BsArrowLeftShort } from "react-icons/bs";
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"404"} />
      <Helmet>
        <title>Page Not Found</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        padding="150px 0 150px 0"
        min-height="100vh"
        background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /cover no-repeat scroll padding-box"
        lg-padding="90px 0 90px 0"
        quarkly-title="404-2"
      >
        <Override
          slot="SectionContent"
          max-width="1220px"
          justify-content="center"
          align-items="center"
          background="--color-lightD1"
        />
        <Box
          align-items="center"
          display="flex"
          justify-content="center"
          flex-direction="column"
        >
          <Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
            404
          </Text>
          <Text
            color="--dark"
            margin="8px 0px 16px 0px"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            font="--headline1"
            letter-spacing="-0.025em"
            lg-margin="0px 0px 16px 0px"
          >
            Page not found
          </Text>
          <Text
            lg-width="80%"
            font="--lead"
            color="--dark"
            margin="0px 0px 36px 0px"
            text-align="center"
            lg-margin="0px 0px 24px 0px"
          >
            Sorry, we couldn’t find the page you’re looking for.
          </Text>
          <LinkBox
            flex-direction="row"
            padding="12px 24px 12px 24px"
            transition="--opacityOut"
            hover-opacity="0.7"
            lg-margin="0px 0px 20px 0px"
            background="--color-primary"
            href="/home"
          >
            <Icon
              category="bs"
              icon={BsArrowLeftShort}
              size="24px"
              margin="4px 4px 0px 0px"
              font="--descirption3"
            />
            <Text margin="0px 0px 0px 0px" font="--descirption3" color="--dark">
              Back to home
            </Text>
          </LinkBox>
        </Box>
      </Section>
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
