import React, { useEffect, useState } from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useHistory, useParams } from "react-router-dom";
import {
  addSingleSubCategoryAlbum,
  AddSingleSubCategory,
} from "redux/Product/product-action";
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let getSubCategoryData = useSelector(
    (state) => state.product.addSingleSubCategory
  );

  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["subCategory"]);
  // let userData = useSelector((state) => state.cms.userData);

  // console.log(getSubCategoryData, "adddd");
  //-----------hide/show-------
  const { token } = useParams();
  useEffect(() => {
    dispatch(addSingleSubCategoryAlbum());
  }, [dispatch]);

  const isShowSubCategoryAlbum = async (selectedDa) => {
    await dispatch(AddSingleSubCategory(selectedDa));
    // console.log(selectedDa, "Selected Object");
    history.push("category-album");
  };
  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    // console.log(selectedData, "show");
  }

  // console.log(getSubCategoryData, "checkkkkk");
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"product-overview"} />
      <Helmet>
        <title>Gallery Category</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.9) 0%,rgba(255, 255, 255, 0.9) 100%),rgba(0, 0, 0, 0.4) url(https://images.unsplash.com/photo-1652667930160-002c7eebb68e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 50% 30% /auto repeat scroll padding-box"
        height="400px"
        align-items="center"
        justify-content="center"
        sm-height="max-content"
      >
        <Override
          slot="SectionContent"
          margin="0px 0px 0px 0px"
          max-width="100%"
          justify-content="center"
          align-items="center"
          sm-margin="40px 0px 40px 0px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 20px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
          color="--lightD1"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL1"
            font="--headline2"
            lg-text-align="center"
            sm-font="--heading"
            padding="0px 16px 0px 0px"
            width="100%"
            id="3115869449"
            className="hoverText"
            onClick={() => isSideBarOpen("3115869449")}
          >
            {data
              ? data["3115869449"]
              : "Our mission is to make your life easier."}
          </Text>
        </Box>
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 0px 0px"
          margin="24px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL1"
            font="--subheader2"
            lg-text-align="center"
            display="flex"
            align-items="flex-start"
            width="100%"
            sm-font="--descirption3"
            id="5049834972"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["5049834972"]
                : "Our mission is to bring your architectural visions to life",
            }}
            onClick={() => isSideBarOpen("5049834972")}
          ></Text>
        </Box>
      </Section>

      <Section
        padding="80px 0 80px 0"
        sm-padding="40px 0 40px 0"
        md-padding="40px 0 80px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
        />

        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          width="100%"
        >
          {getSubCategoryData && getSubCategoryData.length > 0
            ? getSubCategoryData.map((e) => (
                <Box className="item">
                  <LinkBox
                    display="flex"
                    md-flex-direction="column"
                    md-align-items="center"
                    md-justify-content="center"
                    flex-wrap="wrap"
                    sm-width="100%"
                    grid-column="1 / span 1"
                    sm-align-self="auto"
                    sm-grid-column="auto"
                    justify-content="flex-start"
                    href="category-album"
                    onClick={() => isShowSubCategoryAlbum(e)}
                  >
                    <Box
                      width="100%"
                      align-items="flex-start"
                      display="flex"
                      justify-content="flex-start"
                      md-width="100%"
                      md-margin="0px 0px 0px 0px"
                      lg-width="100%"
                      position="relative"
                      // onClick={AlbumData}
                    >
                      <Box width="100%">
                        <Image
                          src={[process.env.REACT_APP_STORAGE_URL_2 + e.image]}
                          border-radius={0}
                          max-width="100%"
                          width="100%"
                          object-fit="cover"
                          lg-max-height="392px"
                          object-position="50% 50%"
                          sm-max-height="213px"
                          height="522px"
                          alt="image"
                          sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                      </Box>
                      <Box
                        display="flex"
                        align-items="center"
                        justify-content="center"
                        position="absolute"
                        width="100%"
                        height="100%"
                        top="0px"
                        right="0px"
                        bottom="auto"
                        left="auto"
                        opacity="0"
                        hover-opacity="1"
                        transition="opacity 0.4s ease-in-out 0s"
                        background="rgba(0, 0, 0, 0.12)"
                        hover-background="rgba(0, 0, 0, 0.12)"
                      />
                    </Box>
                    <Box
                      width="100%"
                      padding="16px 12px 16px 12px"
                      display="flex"
                      align-items="flex-start"
                      justify-content="flex-start"
                      md-width="100%"
                      md-border-width="0px"
                      flex-direction="column"
                    >
                      <Box
                        display="flex"
                        width="100%"
                        justify-content="space-between"
                        align-items="center"
                        margin="0px 0px 6px 0px"
                      >
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--subheader1"
                          margin="0px 0px 0px 0px"
                        >
                          {e.categoryId
                            ? e.categoryId[0].name === "Interior Design"
                              ? e.name
                              : e.name
                            : ""}
                        </Text>
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--subheader1"
                          margin="0px 0px 0px 0px"
                        >
                          🡭
                        </Text>
                      </Box>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--descirption3"
                        color="--grey"
                        opacity=".5"
                      >
                        {e.categoryId
                          ? e.categoryId[0].description === "Interior Design"
                            ? e.description
                            : e.description
                          : ""}
                      </Text>
                    </Box>
                  </LinkBox>
                </Box>
              ))
            : "no-data-sub"}
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
