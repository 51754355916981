import apis from "../../apis/enquireForm";

export const createEnqForm = (payload) => {
  return async (dispatch) => {
    try {
      console.log("hello", payload);

      let { data } = await apis.create(payload);

      console.log(data, "ENQDATA");
      if (data) dispatch(setEnqData(data.data));
      return data;
    } catch (error) {}
  };
};
export const setEnqData = (data) => {
  console.log(data, "hello");
  return {
    type: "SET_ENQ_DATA",
    payload: data,
  };
};
