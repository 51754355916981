import API from "./index";
const ADD_MENU = "add";
const DELETE_MENU = "delete";
const UPDATE_MENU = "update";
const GET_MENU = "clientMenu";

export default {
  getAllMenu() {
    return API.get(transformRoute(GET_MENU));
  },
  addOneMenu(payload) {
    return API.post(transformRoute(ADD_MENU), payload);
  },
  editOneMenu(payload) {
    return API.post(transformRoute(UPDATE_MENU), payload);
  },
  deleteOneMenu(payload) {
    return API.post(transformRoute(DELETE_MENU), payload);
  },
};

const transformRoute = (route) => {
  return `/menu/${route}`;
};
