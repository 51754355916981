import React from "react";
import theme from "theme";
import {
  Theme,
  Image,
  Box,
  LinkBox,
  Text,
  Span,
  Icon,
  Section,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
// import * as Components from "components";
import { MdCallMade } from "react-icons/md";
import { useSelector } from "react-redux/es/exports";

export default () => {
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"portfolio"} />
      <Helmet>
        <title>Portfolio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        padding="80px 0 80px 0"
        lg-padding="56px 0 56px 0"
        sm-padding="32px 0 32px 0"
        align-items="center"
        justify-content="center"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          min-height="100px"
          display="flex"
          margin="0px 0px 26px 0px"
          md-flex-direction="column-reverse"
          lg-flex-direction="column-reverse"
          lg-width="100%"
        >
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="2 / span 2"
            lg-grid-column="2 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
            width="70%"
            margin="0px 24px 0px 0px"
            md-margin="0px 16px 0px 0px"
            md-width="100%"
            lg-width="100%"
          >
            <Box min-width="100px" min-height="100px" width="100%">
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13:47:59.860Z"
                border-radius={0}
                max-width="100%"
                width="100%"
                object-fit="cover"
                lg-max-height="392px"
                object-position="50% 50%"
                height="522px"
                margin="0px 0px 24px 0px"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                width="100%"
                display="grid"
                justify-content="space-between"
                md-width="100%"
                md-justify-content="flex-start"
                grid-template-columns="repeat(6, 1fr)"
                grid-gap="24px"
                overflow-x="auto"
              >
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13:51:49.047Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  md-margin="0px 24px 0px 0px"
                  sm-margin="0px 0 0px 0px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/043.png?v=2023-09-13T13:51:49.024Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  md-margin="0px 24px 0px 0px"
                  sm-margin="0px 0 0px 0px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13:44:35.997Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13:37:14.301Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14:08:35.223Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER.png?v=2023-09-13T14%3A08%3A35.223Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Image
                  src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13:44:35.997Z"
                  display="block"
                  width="132px"
                  height="132px"
                  object-fit="cover"
                  md-width="100px"
                  md-height="100px"
                  sm-width="80px"
                  sm-height="80px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13%3A44%3A35.997Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
            </Box>
          </LinkBox>
          <Box
            margin="0px 0px 32px 0px"
            md-margin="0px 0px 0px 0px"
            lg-margin="0px 0px 33px 0px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            lg-padding="15px 15px 15px 15px"
            sm-margin="0px 0px 10px 0px"
            justify-content="flex-start"
            width="30%"
            md-width="100%"
            lg-width="100%"
            sm-padding="0 0 0 0"
          >
            <Text
              font="--headline2"
              color="--darkL2"
              text-align="left"
              md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
              margin="16px 0px 0px 0px"
              sm-font="--heading"
              sm-margin="0 0px 0px 0px"
            >
              Explore Our Work
            </Text>
            <Text
              font="--subheader2"
              color="--darkL1"
              text-align="left"
              md-width="100%"
              opacity=".5"
              max-width="810px"
              md-font="--subheader1"
              sm-font="--descirption3"
              sm-margin="8px 0px 8px 0px"
            >
              Discover architectural innovation in our portfolio—a collection of
              visionary projects brought to life with creativity and precision
            </Text>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justify-content="space-between"
          margin="0px 0px 40px 0px"
        >
          <Text
            font="--description2"
            color="--darkGrey"
            text-align="center"
            sm-font="--descirption3"
            margin="0px 0px 0px 0px"
          >
            Back to Portfolio
          </Text>
          <Text
            font="--description2"
            color="--darkGrey"
            text-align="center"
            sm-font="--descirption3"
            margin="0px 0px 0px 0px"
          >
            <Span
              color="--grey"
              overflow-wrap="normal"
              word-break="normal"
              white-space="normal"
              text-indent="0"
              text-overflow="clip"
              hyphens="manual"
              user-select="auto"
              pointer-events="auto"
              sm-font="--descirption3"
            >
              &lt; Prev{"  "}
            </Span>
            {"     "}Next &gt;
          </Text>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          lg-display="grid"
          lg-width="100%"
          lg-grid-gap="32px"
          lg-grid-template-columns="repeat(2, 1fr)"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          width="100%"
          sm-grid-gap="24px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13:47:59.810Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20First%20floor%20Layout.png?v=2023-09-13T13%3A47%3A59.810Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 12px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                color="--darkL1"
                opacity="."
                sm-font="--base"
              >
                BAKERS DELIGHT
                <br />
                {"\n\n\n\n"}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13:37:14.292Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 12px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                color="--darkL1"
                opacity="."
                sm-font="--base"
              >
                BAKERS DELIGHT
                <br />
                {"\n\n\n\n"}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13:37:14.301Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 12px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                color="--darkL1"
                opacity="."
                sm-font="--base"
              >
                BAKERS DELIGHT
                <br />
                {"\n\n\n\n"}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14:06:19.339Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13:51:49.047Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13:47:59.860Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/043.png?v=2023-09-13T13:51:49.024Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
                <br />
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12:21:53.242Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 8px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
                <br />
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      {/* <Components.Footer>
        <Override slot="text2" />
        <Override slot="box7" />
        <Override slot="link4" />
        <Override slot="link5" />
      </Components.Footer> */}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
