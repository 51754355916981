import React, { useState } from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
// import {
//   addSingleProduct,
//   defaultImage,
//   defaultVariantData,
// } from "redux/Product/product-action";
// import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  // const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["Privacy Policy"]);
  // let userData = useSelector((state) => state.cms.userData);
  // let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  // console.log(data, "outside");
  // console.log(productData, "hii");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      // console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    // console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contact12"} />
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section lg-padding="0 0 0 0" padding="10px 0 25px 0">
        <Override
          slot="SectionContent"
          padding="0px 25px 0px 25px"
          lg-padding="0px 0px 0px 0px"
          align-items="center"
        />
        <Box
          padding="15px 15px 15px 15px"
          width="100%"
          md-width="100%"
          md-display="flex"
          md-flex-direction="column"
          sm-padding="20px 0px 20px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            margin="0px 0px 40px 0px"
            font="--headline3"
            md-font="--headline3"
            sm-margin="0px 0px 22px 0px"
            sm-font="--subheader4"
            id="4029605495"
            className="hoverText"
            onClick={() => isSideBarOpen("4029605495")}
          >
            {data ? data["4029605495"] : "Privacy Policy"}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="--description2"
            text-align="left"
            width="100%"
            sm-font="--descirption3"
            sm-color="--darkGrey"
            id="2199763210"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["2199763210"]
                : " We prioritize the privacy and security of our users",
            }}
            onClick={() => isSideBarOpen("2199763210")}
          ></Text>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
