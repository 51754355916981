import React, { useState } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Section,
  Image,
  Box,
  LinkBox,
  Icon,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { FaAward } from "react-icons/fa";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useParams } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["About us"]);
  // let userData = useSelector((state) => state.cms.userData);

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    // console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"studio"} />
      <Helmet>
        <title>Studio</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <div
        className="relative"
        // background="linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z) top/cover repeat scroll padding-box"
      >
        <Box
          width="100%"
          id="5255364826"
          className="hoverText"
          onClick={() => isSideBarOpen("5255364826")}
        >
          <img
            src={
              data
                ? data["5255364826"]
                : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
            }
            alt="banner"
            className="h-350px object-cover  w-full linear"
          />
        </Box>

        <div className="absolute top-0 w-full flex items-center h-350px">
          <Section padding="0px" min-height="0px">
            <Box width="100%">
              <Text
                font="--headline3"
                color="--white"
                text-align="center"
                sm-font="--headline4"
                letter-spacing="2px"
                id="6955282607"
                className="hoverText"
                onClick={() => isSideBarOpen("6955282607")}
              >
                {data ? data["6955282607"] : "STUDIO"}
              </Text>
            </Box>
          </Section>
        </div>
      </div>

      <Section
        padding="50px 0 40px 0"
        sm-padding="40px 0 20px 0"
        quarkly-title="Content-9"
        background="#f3f3f3"
        lg-padding="56px 0 56px 0"
      >
        <Override
          slot="SectionContent"
          padding="0px 0 0px 0px"
          lg-padding="0px 0 0px 0px"
          display="flex"
          grid-template-columns="repeat(2, 1fr)"
          align-items="center"
          lg-grid-template-columns="1fr"
          flex-direction="row"
          justify-content="space-between"
          sm-margin="0px 20px 0px 20px"
          sm-width="100%"
          sm-flex-direction="column"
          lg-flex-direction="column"
        />
        <Box
          width="45%"
          sm-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-display="flex"
          sm-align-items="center"
          sm-justify-content="flex-start"
          lg-width="100%"
          lg-margin="0px 0px 24px 0px"
          id="3871788093"
          className="hoverText"
          onClick={() => isSideBarOpen("3871788093")}
        >
          <Image
            src={
              data
                ? data["3871788093"]
                : "https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
            }
            display="block"
            width="100%"
            height="500px"
            object-fit="cover"
            position="static"
            bottom="-39px"
            left="40px"
            right="auto"
            top="auto"
            lg-width="100%"
            lg-height="520px"
            sm-width="100%"
            sm-height="480px"
            sm-bottom="-31px"
            sm-left="auto"
            sm-right="21px"
            sm-top="auto"
            alt="image"
            // srcSet="https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1660580376746-4b18351577e7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
          />
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          width="50%"
          md-display="block"
          sm-width="100%"
          sm-margin="40px 0px 0px 0px"
          lg-width="100%"
        >
          <Box margin="0px 0px 24px 0px" position="relative">
            <Text
              font="--headline2"
              margin="0px 0px 0px 0px"
              position="relative"
              z-index="1"
              sm-font="--heading"
              className="hoverText"
              id="5600111305"
              onClick={() => isSideBarOpen("5600111305")}
            >
              {data ? data["5600111305"] : "JWR Architects"}
            </Text>
          </Box>
          <Text
            margin="0px 0px 25px 0px"
            font="--lead"
            color="#505257"
            className="hoverText"
            sm-margin="0px 0px 0px 0px"
            sm-font="--descirption3"
            id="9331754623"
            dangerouslySetInnerHTML={{
              __html: data ? data["9331754623"] : "Established in 2017",
            }}
            onClick={() => isSideBarOpen("9331754623")}
          ></Text>
        </Box>
      </Section>
      <Section
        padding="48px 0 48px 0"
        sm-padding="40px 0 40px 0"
        quarkly-title="About-10"
        align-items="center"
        justify-content="center"
        background="#f3f3f3f3"
        position="static"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          lg-align-items="center"
          lg-justify-content="space-between"
          justify-content="space-between"
          lg-display="flex"
          lg-flex-direction="column-reverse"
          sm-flex-direction="column-reverse"
          display="flex"
        />
        <Box
          display="flex"
          width="45%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="flex-start"
          lg-margin="0px 0px 04px 0px"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          margin="0px 0px 0px 0px"
          padding="25px 25px 25px 25px"
          lg-width="100%"
          lg-padding="0px 0px 0px 0"
          sm-width="100%"
          height="max-content"
        >
          <Text
            margin="0px 0px 20px 0px"
            color="--darkL1"
            font="--headline2"
            lg-text-align="left"
            sm-font="--heading"
            md-font="--headline2"
            lg-font="--headline3"
            id="6434556860"
            className="hoverText"
            onClick={() => isSideBarOpen("6434556860")}
          >
            {data ? data["6434556860"] : "Ar. Jeff & Rajeshwari Rodricks"}
          </Text>
          <LinkBox
            align-items="center"
            justify-content="center"
            width="100%"
            padding="16px 0px 16px 0px"
            background="--color-white"
            margin="0px 0px 16px 0px"
            border-radius="8px"
          >
            <Text
              color="--darkL1"
              font="--button1"
              lg-text-align="left"
              sm-font="--subheader2"
              md-font="--lead52"
              lg-font="--headline3"
              margin="0 0px 0 0px"
              id="2275090410"
              className="hoverText"
              onClick={() => isSideBarOpen("2275090410")}
            >
              {data ? data["2275090410"] : "Design"}
            </Text>
          </LinkBox>
          <LinkBox
            align-items="center"
            justify-content="center"
            width="100%"
            padding="16px 0px 16px 0px"
            background="--color-white"
            margin="0px 0px 16px 0px"
            border-radius="8px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--button1"
              lg-text-align="left"
              sm-font="--subheader2"
              md-font="--lead52"
              lg-font="--headline3"
              id="3432781601"
              className="hoverText"
              onClick={() => isSideBarOpen("3432781601")}
            >
              {data ? data["3432781601"] : "Functionality"}
            </Text>
          </LinkBox>
          <LinkBox
            align-items="center"
            justify-content="center"
            width="100%"
            padding="16px 0px 16px 0px"
            background="--color-white"
            margin="0px 0px 16px 0px"
            border-radius="8px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--button1"
              lg-text-align="left"
              sm-font="--subheader2"
              md-font="--lead52"
              lg-font="--headline3"
              id="2134470371"
              className="hoverText"
              onClick={() => isSideBarOpen("2134470371")}
            >
              {data ? data["2134470371"] : "Aesthetic"}
            </Text>
          </LinkBox>
          <LinkBox
            align-items="center"
            justify-content="center"
            width="100%"
            padding="16px 0px 16px 0px"
            background="--color-white"
            margin="0px 0px 16px 0px"
            border-radius="8px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--button1"
              lg-text-align="left"
              sm-font="--subheader2"
              md-font="--lead52"
              lg-font="--headline3"
              id="9839467867"
              className="hoverText"
              onClick={() => isSideBarOpen("9839467867")}
            >
              {data ? data["9839467867"] : "Composition"}
            </Text>
          </LinkBox>
          <LinkBox
            align-items="center"
            justify-content="center"
            width="100%"
            padding="16px 0px 16px 0px"
            background="--color-white"
            border-radius="8px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL1"
              font="--button1"
              lg-text-align="left"
              sm-font="--subheader2"
              md-font="--lead52"
              lg-font="--headline3"
              id="3207841017"
              className="hoverText"
              onClick={() => isSideBarOpen("3207841017")}
            >
              {data ? data["3207841017"] : "Innovation"}
            </Text>
          </LinkBox>
        </Box>
        <Box
          display="flex"
          width="50% "
          padding="0px 0px 0px 25"
          lg-width="100%"
          md-width="100%"
          sm-flex-direction="column"
          sm-margin="0px 0px 8px 0px"
          className="lg:gap-8"
        >
          <Box
            id="8325793149"
            className="hoverText"
            width="100%"
            onClick={() => isSideBarOpen("8325793149")}
          >
            <Image
              src={
                data
                  ? data["8325793149"]
                  : "https://uploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13:51:53.595Z"
              }
              display="block"
              width="100%"
              height="100%"
              object-fit="cover"
              lg-width="100%"
              lg-height="520px"
              sm-width="100%"
              sm-height="350px"
              sm-margin="0px 0px 24px 0px"
              lg-margin="0px 0px 24px 0px"
              margin="0px 40px 0px 0px"
              md-margin="0px 24px 24px 0px"
              alt="image"
              // srcSet="https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/WhatsApp-Image-2021-10-20-at-10.18.58-AM-Copy.jpeg?v=2023-09-21T13%3A51%3A53.595Z&quality=85&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
          <Box
            id="6572143103"
            className="hoverText"
            width="100%"
            onClick={() => isSideBarOpen("6572143103")}
          >
            <Image
              src={
                data
                  ? data["6572143103"]
                  : "https://uploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08:20:43.546Z"
              }
              display="block"
              width="100%"
              height="100%"
              object-fit="cover"
              lg-width="100%"
              lg-height="520px"
              sm-width="100%"
              sm-height="350px"
              sm-margin="0px 0px 0px 0px"
              lg-margin="0px 0px 24px 0px"
              alt="image"
              // srcSet="https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6503f96091dc650018455a31/images/IMG_2851%20%281%29.jpg?v=2023-11-07T08%3A20%3A43.546Z&quality=85&w=3200 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
        </Box>
      </Section>
      <Section padding="40px 0 40px 0" sm-padding="40px 0 40px 0">
        <Text
          margin="0px 0px 40px 0px"
          color="--darkL1"
          font="--headline2"
          lg-text-align="center"
          sm-font="--heading"
          padding="0px 16px 0px 0px"
          width="100%"
          sm-margin="0px 0px 24px 0px"
          sm-text-align="left"
          md-text-align="left"
          id="0765998261"
          className="hoverText"
          onClick={() => isSideBarOpen("0765998261")}
        >
          {data ? data["0765998261"] : "Awards &  Achievements"}
        </Text>
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px 4%"
          md-grid-template-columns="1fr"
          width="100%"
          grid-template-rows="repeat(1, 1fr)"
        >
          <Box
            align-items="center"
            justify-content="center"
            flex-direction="column"
            display="flex"
            padding="16px 16px 16px 16px"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
          >
            <Icon
              category="fa"
              icon={FaAward}
              margin="0px 0px 22px 0px"
              color="--dark"
              size="48px"
              width="80px"
              height="80px"
            />
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--subheader4"
              lg-text-align="center"
              text-align="center"
              sm-text-align="center"
              id="4991060855"
              className="hoverText"
              onClick={() => isSideBarOpen("4991060855")}
            >
              {data
                ? data["4991060855"]
                : "Futuristic & outstanding architecture"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--subheader2"
              text-align="center"
              flex="1 0 auto"
              height="max-content"
              min-height={0}
              min-width={0}
              id="1478062912"
              className="hoverText"
              onClick={() => isSideBarOpen("1478062912")}
            >
              {data ? data["1478062912"] : "- 2023"}
            </Text>
          </Box>
          <Box
            align-items="center"
            justify-content="center"
            flex-direction="column"
            display="flex"
            padding="16px 16px 16px 16px"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
          >
            <Icon
              category="fa"
              icon={FaAward}
              margin="0px 0px 22px 0px"
              color="--dark"
              size="48px"
              width="80px"
              height="80px"
            />
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--subheader4"
              lg-text-align="center"
              text-align="center"
              sm-text-align="center"
              id="2573849195"
              className="hoverText"
              onClick={() => isSideBarOpen("2573849195")}
            >
              {data
                ? data["2573849195"]
                : "40 under 40 elite & trendsetter architect & designer of the year"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--subheader2"
              text-align="center"
              flex="1 0 auto"
              height="max-content"
              min-height={0}
              min-width={0}
              className="hoverText"
              id="1663521474"
              onClick={() => isSideBarOpen("1663521474")}
            >
              {data ? data["1663521474"] : "- 2023"}
            </Text>
          </Box>
          <Box
            align-items="center"
            justify-content="center"
            flex-direction="column"
            display="flex"
            padding="16px 16px 16px 16px"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
          >
            <Icon
              category="fa"
              icon={FaAward}
              margin="0px 0px 22px 0px"
              color="--dark"
              size="48px"
              width="80px"
              height="80px"
            />
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--subheader4"
              lg-text-align="center"
              text-align="center"
              id="5665861690"
              className="hoverText"
              onClick={() => isSideBarOpen("5665861690")}
            >
              {data ? data["5665861690"] : "Brandz magazine"}
            </Text>
            <Text
              margin="0px 0px 8px 0px"
              color="--greyD3"
              font="--subheader2"
              text-align="center"
              flex="1 0 auto"
              height="max-content"
              min-height={0}
              min-width={0}
              id="3847023588"
              className="hoverText"
              onClick={() => isSideBarOpen("3847023588")}
            >
              {data ? data["3847023588"] : "- 2022"}
            </Text>
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--descirption3"
              lg-text-align="center"
              text-align="right"
              text-decoration-line="underline"
            >
              <Link
                href="https://brandzmagazine.com/the-gold-medalist-architect-who-now-offers-complete-architectural-solution-to-end-users-through-his-venture/?fbclid=PAAaZbco1Msd168Z-CXp4uAXea19gSs9N6e0wwuYqLThVj9X1nx57mRfwyJjc_aem_AeEJZBDH0UtdkHqDtSxatjIP4KrsG7_qsMmo9stdriqT7PQJUPBXXbrZ3jyIVWDGovs"
                color="--primary"
                target="_blank"
              >
                view Article
              </Link>
            </Text>
          </Box>
          <Box
            align-items="center"
            justify-content="center"
            flex-direction="column"
            display="flex"
            padding="16px 16px 16px 16px"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
          >
            <Icon
              category="fa"
              icon={FaAward}
              margin="0px 0px 22px 0px"
              color="--dark"
              size="48px"
              width="80px"
              height="80px"
            />
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--subheader4"
              lg-text-align="center"
              text-align="center"
              sm-text-align="center"
              id="0293799715"
              className="hoverText"
              onClick={() => isSideBarOpen("0293799715")}
            >
              {data
                ? data["0293799715"]
                : "Most innovative & cost-effective modern architecture firm in"}
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--subheader2"
              text-align="center"
              flex="1 0 auto"
              height="max-content"
              min-height={0}
              min-width={0}
              id="5178472617"
              className="hoverText"
              onClick={() => isSideBarOpen("5178472617")}
            >
              {data ? data["5178472617"] : "- 2021"}
            </Text>
          </Box>
          <Box
            align-items="center"
            justify-content="center"
            flex-direction="column"
            display="flex"
            padding="16px 16px 16px 16px"
            border-width="1px"
            border-style="solid"
            border-color="--color-grey"
          >
            <Icon
              category="fa"
              icon={FaAward}
              margin="0px 0px 22px 0px"
              color="--dark"
              size="48px"
              width="80px"
              height="80px"
            />
            <Text
              margin="0px 0px 11px 0px"
              color="--darkL2"
              font="--subheader4"
              lg-text-align="center"
              text-align="center"
              sm-text-align="center"
              id="2187704952"
              className="hoverText"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["2187704952"]
                  : "National business & service",
              }}
              onClick={() => isSideBarOpen("2187704952")}
            ></Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD3"
              font="--subheader2"
              text-align="center"
              flex="1 0 auto"
              height="max-content"
              min-height={0}
              min-width={0}
              id="0999205025"
              className="hoverText"
              onClick={() => isSideBarOpen("0999205025")}
            >
              {data ? data["0999205025"] : "- 2021"}
            </Text>
          </Box>
        </Box>
      </Section>
      {/* <Section
        padding="80px 0 80px 0"
        background="--white"
        sm-padding="40px 0 40px 0"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          margin="0px 0px 56px 0px"
          width="100%"
          sm-margin="0px 0px 10px 0px"
        >
          <Text
            margin="0px 0px 16px 0px"
            color="--darkL2"
            font="--headline2"
            text-align="center"
            sm-font="--heading"
            sm-margin="0px 0px 8px 0px"
            id="4885863329"
            className="hoverText"
            onClick={() => isSideBarOpen("4885863329")}
          >
            {data ? data["4885863329"] : "Testimonials"}
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            text-align="center"
            font="--lead"
            sm-font="--description2"
            id="4561603984"
            className="hoverText"
            onClick={() => isSideBarOpen("4561603984")}
          >
            {data
              ? data["4561603984"]
              : "Sign up with your email address to receive news and updates."}
          </Text>
        </Box>
        <Box
          display="grid"
          lg-flex-wrap="wrap"
          align-items="stretch"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="16px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
        >
          <Box
            margin="0px 30px 0px 0px"
            lg-margin="0px 0px 0px 0px"
            lg-padding="0px 15px 0px 0px"
            sm-padding="8px 8px 8px 8px"
            sm-margin="40px 0px 0px 0px"
            display="flex"
            flex-direction="column"
          >
            <Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
              “
            </Text>
            <Text
              margin="0px 0px 100px 0px"
              color="--darkL2"
              font="--lead"
              lg-margin="0px 0px 30px 0px"
              sm-margin="0px 0px 30px 0px"
              flex="1 0 auto"
              sm-font="--description2"
              sm-color="--darkGrey"
              id="0184513870"
              className="hoverText"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["0184513870"]
                  : "Working with JWR Architects was an absolute pleasure",
              }}
              onClick={() => isSideBarOpen("0184513870")}
            ></Text>
            <Box
              display="flex"
              margin="0px 17px 0px 0px"
              align-items="center"
              flex-direction="row"
            >
              <Box
                id="0091891063"
                className="hoverText"
                onClick={() => isSideBarOpen("0091891063")}
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["0091891063"]
                      : "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19:47:23.498Z"
                  }
                  border-radius="50зч"
                  margin="0px 17px 0px 0px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image6.png?v=2021-08-25T19%3A47%3A23.498Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box>
                <Text
                  color="--darkL2"
                  font="--lead20"
                  margin="0px 0px 2px 0px"
                  id="6046906246"
                  className="hoverText"
                  onClick={() => isSideBarOpen("6046906246")}
                >
                  {data ? data["6046906246"] : "Sam Smith"}
                </Text>
                <Text
                  color="--darkL2"
                  font="--base"
                  margin="0px 0px 0px 0px"
                  opacity=".5"
                  id="4998208991"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4998208991")}
                >
                  {data ? data["4998208991"] : "PM"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            margin="0px 30px 0px 0px"
            lg-margin="0px 0px 0px 0px"
            lg-padding="0px 0px 0px 15px"
            sm-padding="0px 0px 0px 0px"
            sm-margin="40px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            sm-display="none"
          >
            <Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
              “
            </Text>
            <Text
              margin="0px 0px 100px 0px"
              color="--darkL2"
              font="--lead"
              lg-margin="0px 0px 30px 0px"
              sm-margin="0px 0px 30px 0px"
              flex="1 0 auto"
              id="6302913330"
              className="hoverText"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["6302913330"]
                  : "I hired JWR Architects to renovate my historic home",
              }}
              onClick={() => isSideBarOpen("6302913330")}
            ></Text>
            <Box
              display="flex"
              margin="0px 17px 0px 0px"
              align-items="center"
              flex-direction="row"
            >
              <Box
                id="7335420616"
                className="hoverText"
                onClick={() => isSideBarOpen("7335420616")}
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["7335420616"]
                      : "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19:47:16.297Z"
                  }
                  border-radius="50зч"
                  margin="0px 17px 0px 0px"
                  className="hoverText"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image5.png?v=2021-08-25T19%3A47%3A16.297Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box>
                <Text
                  color="--darkL2"
                  font="--lead20"
                  margin="0px 0px 2px 0px"
                  id="1425502547"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1425502547")}
                >
                  {data ? data["1425502547"] : "Mason Johnson"}
                </Text>
                <Text
                  color="--darkL2"
                  font="--base"
                  margin="0px 0px 0px 0px"
                  opacity=".5"
                  id="9248813589"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9248813589")}
                >
                  {data ? data["9248813589"] : "Writer"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            margin="0px 30px 0px 0px"
            lg-padding="0px 15px 0px 0px"
            lg-margin="40px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            sm-margin="40px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            lg-display="none"
          >
            <Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
              “
            </Text>
            <Text
              margin="0px 0px 100px 0px"
              color="--darkL2"
              font="--lead"
              lg-margin="0px 0px 30px 0px"
              sm-margin="0px 0px 30px 0px"
              flex="1 0 auto"
              id="8000603820"
              className="hoverText"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["8000603820"]
                  : "JWR Architects played a pivotal role in the success",
              }}
              onClick={() => isSideBarOpen("8000603820")}
            ></Text>
            <Box
              display="flex"
              margin="0px 17px 0px 0px"
              align-items="center"
              flex-direction="row"
            >
              <Box
                id="3604364337"
                className="hoverText"
                onClick={() => isSideBarOpen("3604364337")}
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["3604364337"]
                      : "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19:47:08.343Z"
                  }
                  border-radius="50зч"
                  margin="0px 17px 0px 0px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image4.png?v=2021-08-25T19%3A47%3A08.343Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box>
                <Text
                  color="--darkL2"
                  font="--lead20"
                  margin="0px 0px 2px 0px"
                  id="4625552061"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4625552061")}
                >
                  {data ? data["4625552061"] : "Adriana Williams"}
                </Text>
                <Text
                  color="--darkL2"
                  font="--base"
                  margin="0px 0px 0px 0px"
                  opacity=".5"
                  id="1888113660"
                  className="hoverText"
                  onClick={() => isSideBarOpen("1888113660")}
                >
                  {data ? data["1888113660"] : "PO"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            lg-padding="0px 0px 0px 15px"
            lg-margin="40px 0px 0px 0px"
            sm-padding="0px 0px 0px 0px"
            sm-margin="40px 0px 0px 0px"
            display="flex"
            flex-direction="column"
            lg-display="none"
          >
            <Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
              “
            </Text>
            <Text
              margin="0px 0px 100px 0px"
              color="--darkL2"
              font="--lead"
              lg-margin="0px 0px 30px 0px"
              sm-margin="0px 0px 30px 0px"
              flex="1 0 auto"
              id="2465564245"
              className="hoverText"
              dangerouslySetInnerHTML={{
                __html: data
                  ? data["2465564245"]
                  : "The team at JWR Architects transformed",
              }}
              onClick={() => isSideBarOpen("2465564245")}
            ></Text>
            <Box
              display="flex"
              margin="0px 17px 0px 0px"
              align-items="center"
              flex-direction="row"
            >
              <Box
                id="3894882120"
                className="hoverText"
                onClick={() => isSideBarOpen("3894882120")}
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["3894882120"]
                      : "https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19:46:11.754Z"
                  }
                  border-radius="50зч"
                  margin="0px 17px 0px 0px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/image-3.png?v=2021-08-25T19%3A46%3A11.754Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box>
                <Text
                  color="--darkL2"
                  font="--lead20"
                  margin="0px 0px 2px 0px"
                  id="4926139713"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4926139713")}
                >
                  {data ? data["4926139713"] : "Ethan Tremblay"}
                </Text>
                <Text
                  color="--darkL2"
                  font="--base"
                  margin="0px 0px 0px 0px"
                  opacity=".5"
                  id="6147640580"
                  className="hoverText"
                  onClick={() => isSideBarOpen("6147640580")}
                >
                  {data ? data["6147640580"] : "UI/UX Designer"}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section> */}
      {/* <Components.Footer>
        <Override slot="text2" />
      </Components.Footer> */}

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
