import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Image, Box, Section } from "@quarkly/widgets";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { addSingleSubCategoryAlbum } from "../redux/Product/product-action";

export default () => {
  const dispatch = useDispatch();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  let selectedPortfolio = useSelector(
    (state) => state.product.selectedPortfolio
  );

  useEffect(() => {
    dispatch(addSingleSubCategoryAlbum());
  }, [dispatch]);

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index1112"} />
      <Helmet>
        <title>Category Album</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        padding="80px 0 80px 0"
        lg-padding="56px 0 56px 0"
        sm-padding="32px 0 32px 0"
        align-items="center"
        justify-content="center"
      >
        <Override slot="SectionContent" />
        {/* <Box
          min-height="100px"
          display="flex"
          margin="0px 0px 26px 0px"
          md-flex-direction="column-reverse"
          lg-flex-direction="column-reverse"
          lg-width="100%"
        >
          <Box
            width="70%"
            margin="0px 24px 0px 0px"
            md-margin="0px 16px 0px 0px"
            md-width="100%"
            lg-width="100%"
          >
            <Box min-width="100px" min-height="100px" width="100%">
              <ImageGallery
                items={getImage(getSubCategoryData.album)}
                border-radius={0}
                max-width="100%"
                width="100%"
                object-fit="cover"
                lg-max-height="392px"
                object-position="50% 50%"
                height="522px"
                margin="0px 0px 24px 0px"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
          </Box>
          <Box
            margin="0px 0px 32px 0px"
            md-margin="0px 0px 0px 0px"
            lg-margin="0px 0px 33px 0px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            lg-padding="15px 15px 15px 15px"
            sm-margin="0px 0px 10px 0px"
            justify-content="flex-start"
            width="30%"
            md-width="100%"
            lg-width="100%"
            sm-padding="0 0 0 0"
          >
            <Text
              font="--headline2"
              color="--darkL2"
              text-align="left"
              md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
              margin="16px 0px 0px 0px"
              sm-font="--heading"
              sm-margin="0 0px 0px 0px"
            >
              {getSubCategoryData.name}
            </Text>
            <Text
              font="--subheader2"
              color="--darkL1"
              text-align="left"
              md-width="100%"
              opacity=".5"
              max-width="810px"
              md-font="--subheader1"
              sm-font="--descirption3"
              sm-margin="8px 0px 8px 0px"
            >
              {getSubCategoryData.description}
            </Text>
          </Box>
        </Box> */}
        <Box
          min-width="100px"
          min-height="100px"
          lg-display="grid"
          lg-width="100%"
          lg-grid-gap="32px"
          lg-grid-template-columns="repeat(2, 1fr)"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          grid-gap="32px"
          width="100%"
          sm-grid-gap="24px"
        >
          <PhotoProvider>
            {selectedPortfolio.album && selectedPortfolio.album.length > 0
              ? selectedPortfolio.album.map((e, index, item) => (
                  <Box
                    min-width="100px"
                    min-height="100px"
                    display="block"
                    grid-template-columns="repeat(3, 1fr)"
                  >
                    <Box
                      min-width="100px"
                      min-height="100px"
                      height="500px"
                      md-height="400px"
                      sm-height="300px"
                      className=""
                      key={item}
                    >
                      <PhotoView
                        key={index}
                        src={`${process.env.REACT_APP_STORAGE_URL_2}${e}`}
                      >
                        <Image
                          src={`${process.env.REACT_APP_STORAGE_URL_2}${e}`}
                          border-radius={0}
                          max-width="100%"
                          width="100%"
                          object-fit="cover"
                          lg-max-height="392px"
                          object-position="50% 50%"
                          height="100%"
                          margin="0px 0px 24px 0px"
                          sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                      </PhotoView>
                    </Box>
                  </Box>
                ))
              : "no-data"}
          </PhotoProvider>
          {/* <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13:37:14.292Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 12px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                color="--darkL1"
                opacity="."
                sm-font="--base"
              >
                BAKERS DELIGHT
                <br />
                {"\n\n\n\n"}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13:37:14.301Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 12px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                color="--darkL1"
                opacity="."
                sm-font="--base"
              >
                BAKERS DELIGHT
                <br />
                {"\n\n\n\n"}
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14:06:19.339Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/COVER%20%281%29.png?v=2023-09-13T14%3A06%3A19.339Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13:51:49.047Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/14%20edit.png?v=2023-09-13T13%3A51%3A49.047Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13:47:59.860Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/043.png?v=2023-09-13T13:51:49.024Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 4px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
                <br />
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="block"
            grid-template-columns="repeat(3, 1fr)"
          >
            <Box
              min-width="100px"
              min-height="100px"
              height="400px"
              sm-height="250px"
            >
              <Image
                src="https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12:21:53.242Z"
                display="block"
                width="100%"
                height="100%"
                object-fit="cover"
                alt="image"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01%201.png?v=2023-09-12T12%3A21%3A53.242Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box min-width="100px" min-height="100px">
              <Box
                display="flex"
                justify-content="space-between"
                align-items="center"
                padding="16px 0px 0px 0px"
                margin="0px 0px 8px 0px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--subheader2"
                  sm-font="--descirption3"
                  text-transform="uppercase"
                >
                  Architect portfolio
                </Text>
                <Icon category="md" icon={MdCallMade} size="26px" />
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader1"
                sm-font="--base"
              >
                GUYBRO
                <br />
              </Text>
            </Box>
          </Box> */}
        </Box>
      </Section>

      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
