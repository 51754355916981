import React, { useEffect, useState } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllMenu } from "redux/Menu/menu-actions";
import { setLoading } from "redux/Menu/menu-actions";
import { Image, Text } from "@quarkly/widgets";
import { NavLink } from "react-router-dom";

const NavbarHeader = (props) => {
  const [expand, setExpand] = useState(false);

  const handleToggle = () => {
    setExpand((state) => !state);
  };

  const dispatch = useDispatch();

  let allMenuData = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let brandDetails = useSelector((state) => state.brandDetails);

  useEffect(() => {
    async function mount() {
      await dispatch(setLoading(true));
      await dispatch(getAllMenu());

      const timer = setTimeout(async () => {
        await dispatch(setLoading(false));
      }, 2000);

      return () => clearTimeout(timer);
    }
    mount();
  }, [dispatch]);

  function getImages(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }

  function applyFontFamily() {
    if (brandDetails && brandDetails.hasOwnProperty("primaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.primaryFont
      );

    if (brandDetails && brandDetails.hasOwnProperty("secondaryFont"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.secondaryFont
      );

    if (brandDetails && brandDetails.hasOwnProperty("tertiaryColor"))
      document.body.fontFamily.setProperty(
        "--qtheme-fontFamily-sans",
        brandDetails.tertiaryColor
      );
  }
  applyFontFamily();

  return (
    <Navbar
      expanded={expand}
      expand="lg"
      className="zindex fixed flex top-0 w-full bg-white"
    >
      <div className="flex justify-between items-center container mx-auto w-full ">
        <div className="lg:block  md:hidden hidden">
          <Navbar.Brand className="text-white flex  items-center">
            <NavLink to={"/Home"}>
              <Image
                display="block"
                width="140px"
                height="72px"
                object-fit="contain"
                src={getImages(brandProfile)}
                object-position="50% 0%"
                alt="logo"
              />
            </NavLink>
          </Navbar.Brand>
        </div>
        <div className="widthsm ">
          <div className="flex justify-between items-center w-full px-2 ">
            <div className="lg:hidden  md:block block">
              <NavLink to={"/Home"}>
                <Image
                  display="block"
                  object-fit="contain"
                  width="100px"
                  height="55px"
                  src={getImages(brandProfile)}
                  object-position="50% 0%"
                  alt="logo"
                />
              </NavLink>
            </div>
            <div>
              <Navbar.Toggle
                onClick={handleToggle}
                aria-controls="navbarScroll"
                className="w-full "
              />
            </div>
          </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto flex items-center nav-pad"
              style={{ maxHeight: "200px" }}
              navbarScroll
            >
              <div className="flex">
                <div className="lg:flex md:flex  block w-full md-space-between items-center lg:my-0 md:my-0 my-2">
                  {allMenuData.data
                    ? allMenuData.data.map((e, i) => (
                        <NavLink
                          exact
                          to={e.pageData[0].name}
                          color="--primary"
                          className="mr-38px "
                          key={i}
                          onClick={() => setExpand(false)}
                        >
                          <Text
                            margin="0px 0px 0px 0px"
                            sm-margin="0px 0px 0px 0px"
                            font="--subheader1"
                            color="--grey"
                            hover-color="--primary"
                            sm-padding="10px 6px 6px 6px"
                          >
                            {e.menuName}
                          </Text>
                        </NavLink>
                      ))
                    : "no-data"}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
};

export default NavbarHeader;
