import React, { useEffect } from "react";
import Home from "pages/Home";
import Studio from "pages/Studio";
import ProductOverview from "pages/ProductOverview";
import Portfolio from "pages/Portfolio";
// import ArchitectPortfolio from "pages/ArchitectPortfolio";
import InteriorDesignPortfolio from "pages/InteriorDesignPortfolio";
import Contact from "pages/Contact";
import Terms from "pages/Terms";
import Policy from "pages/Policy";
import Page404 from "pages/Page404";
import { persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import axios from "axios";

//apis
import { getCms } from "redux/CMS/cms-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { getVariations } from "redux/variations/variation-action";
import { Footer } from "components";
import NavbarHeader from "components/NavbarHeader";
import Gallery from "pages/Gallery";
import ScrollToTop from "components/ScrollToTop";
const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => {
  const dispatch = useDispatch();
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  const appendgtag = async () => {
    const headers = {
      "Content-Type": "application/json",
      "community-name": process.env.REACT_APP_COMMUNITY_NAME,
    };
    let { data } = await axios.post(
      process.env.REACT_APP_BASE_URL + "project/getProject",
      {},
      {
        headers: headers,
      }
    );
    // console.log(data.data, data.data.gTag, "Yooo Anish");
    if (data.data && data.data.gTag) {
      let s = document.createElement("script");
      s.async = true;
      s.src = "https://www.googletagmanager.com/gtag/js?id=" + data.data.gTag;
      s.onload = () => {
        window.datalayer = window.datalayer || [];
        window.datalayer.push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
          js: new Date(),
          config: data.data.gTag,
        });
      };
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  };
  useEffect(() => {
    appendgtag();
    async function mount() {
      dispatch(getVariations({ pageId: "6299b8fcd3a5d92deb0b908d" }));

      await dispatch(getCms());
      await dispatch(getProject());
      await dispatch(
        getBrandProfile({
          projectId: project._id,
          projectName: project.projectName,
        })
      );
    }
    mount();
  }, [dispatch, project._id, project.projectName]);

  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();
  return (
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <GlobalStyles />
        <ScrollToTop />

        {/* <Nav /> */}
        <NavbarHeader />
        <div className="mt-98px">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/home/:token" component={Home} />
            <Route exact path="/about us" component={Studio} />
            <Route exact path="/about us/:token" component={Studio} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/gallery/:token" component={Gallery} />
            <Route exact path="/gallery-category" component={ProductOverview} />
            <Route
              exact
              path="/gallery-category/:token"
              component={ProductOverview}
            />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/portfolio/:token" component={Portfolio} />
            {/* <Route
              exact
              path="/architect-portolio"
              component={ArchitectPortfolio}
            /> */}
            <Route
              exact
              path="/category-album"
              component={InteriorDesignPortfolio}
            />
            <Route
              exact
              path="/category-album/:token"
              component={InteriorDesignPortfolio}
            />
            <Route exact path="/contact us" component={Contact} />
            <Route exact path="/contact us/:token" component={Contact} />
            <Route exact path="/terms-conditions" component={Terms} />
            <Route exact path="/terms-conditions/:token" component={Terms} />
            <Route exact path="/policy" component={Policy} />
            <Route exact path="/policy/:token" component={Policy} />
            <Route component={Page404} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </PersistGate>
  );
};
