import apis from "../../apis/product";

export const getAllCategory = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        // limit: 10,
        // offset: 0,
      };
      let { data } = await apis.getAllCategory(payload);
      // console.log("kishan", data.data.data);
      dispatch(setProductCategory(data.data.data));

      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSubCategory = (payload) => {
  return async (dispatch) => {
    try {
      let { data } = await apis.getAllSubCategory({
        ...payload,
      });

      if (data) {
        dispatch(addSingleSubCategory(data.data.data));
      }
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

export const AddSingleSubCategory = (payloads) => {
  // var categoryName = payload.categoryName;
  return async (dispatch, payload) => {
    try {
      dispatch(addSingleSubCategory(payloads));

      return payload;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getProduct = (payload) => {
  return async (dispatch, payload) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        subcategoryName: "Interior Design",
      };
      // console.log("testSachin", payload);
      // dispatch(setProduct([]));
      let { data } = await apis.getProduct(payload);
      dispatch(setProduct(data.data.data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const setProductCategory = (data) => {
  return {
    type: "setProductCategory",
    payload: data,
  };
};
export const setIsLoadingCat = (data) => {
  return {
    type: "setIsLoadingCat",
    payload: data,
  };
};
export const setProduct = (data) => {
  return {
    type: "setProduct",
    payload: data,
  };
};
export const setProductSubCategory = (data) => {
  return {
    type: "setProductSubCategory",
    payload: data,
  };
};

export const addSingleSubCategory = (data) => {
  // console.log(data, "subcat");
  return {
    type: "ADD_SINGLE_SubCategory",
    payload: data,
  };
};

export const addSingleSubCategoryAlbum = (data) => {
  // console.log(data, "subcat");
  return {
    type: "ADD_SINGLE_SubCategoryAlbum",
    payload: data,
  };
};

export const selectedPortfolio = (data) => {
  return {
    type: "setSelectedPortfolio",
    payload: data,
  };
};
