import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Section,
  Icon,
  Input,
  Link,
  Strong,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";

import { GoLocation, GoMail } from "react-icons/go";
import { MdPhone } from "react-icons/md";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { Checkbox } from "element-react";

import { createContact } from "redux/marcom/marcom-action";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, "Only alphabetic characters allowed")
    .min(2, "Full name must be at least 2 character")
    .max(50, "Full name must be 50 characters or less")
    .required("Full name is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter valid phone number")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email id is required"),
  message: Yup.string().required("Message is required"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
);

export default () => {
  const formik = useFormik({
    initialValues: {
      username: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formik.values.username.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      description: formik.values.message.trim(),
    };

    if (
      payload.name !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== ""
    ) {
      let data = await dispatch(createContact(payload));
      // console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Congratulations",
          text: "Your query been successfully sent.",
          icon: "success",
        });
        // console.log(data.code, "registerCode");
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all the required details",
        icon: "error",
      });
      // console.log(data.message, "datamessage");
    }
  };

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["Contact Us"]);
  // let userData = useSelector((state) => state.cms.userData);

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      // console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    // console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contact"} />
      <Helmet>
        <title>Contact Us</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <div
        className="relative"
        // background="linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z) top/cover repeat scroll padding-box"
      >
        <Box
          width="100%"
          id="7960558110"
          className="hoverText"
          onClick={() => isSideBarOpen("7960558110")}
        >
          <img
            src={
              data
                ? data["7960558110"]
                : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
            }
            alt="banner"
            className="h-305px object-cover  w-full linear filter"
          />
        </Box>

        <div className="absolute top-0 w-full flex items-center h-305px">
          <Section padding="0px" min-height="0px">
            <Box
              min-width="100px"
              min-height="100px"
              lg-width="100%"
              display="flex"
              flex-direction="column"
              justify-content="center"
              lg-margin="0px 0px 0px 0px"
              align-items="center"
              sm-margin="0px 0px 0px 0px"
              sm-padding="0px 0px 0px 0px"
              md-padding="0px 0px 0px 0px"
              margin="0px 0px 0px 0px"
              width="95%"
              lg-align-items="center"
              lg-justify-content="center"
            >
              <Text
                margin="0px 0px 20px 0px"
                font="--headline2"
                color="--light"
                sm-font="--heading"
                md-text-align="center"
                sm-text-align="center"
                id="3202450779"
                className="hoverText"
                onClick={() => isSideBarOpen("3202450779")}
              >
                {data
                  ? data["3202450779"]
                  : "Ready to discuss your next project?"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--subheader2"
                color="--lightD2"
                lg-margin="0px 0px 20px 0px"
                md-width="100%"
                sm-font="--descirption3"
                sm-margin="0px 0px 0 0px"
                text-align="center"
                id="6376988790"
                className="hoverText"
                dangerouslySetInnerHTML={{
                  __html: data
                    ? data["6376988790"]
                    : "Our highly trained support staff are available 24/7",
                }}
                onClick={() => isSideBarOpen("6376988790")}
              ></Text>
            </Box>
          </Section>
        </div>
      </div>

      <Section
        color="--light"
        padding="60px 0 60px 0"
        sm-padding="40px 0"
        position="relative"
        quarkly-title="Form-1"
        background="--white"
      >
        <Override slot="SectionContent" height="max-content" />
        <Box display="flex" flex-wrap="wrap" flex-direction="row">
          <Box
            width="50%"
            md-width="100%"
            height="max-content"
            sm-margin="0px 0px 24px 0px"
          >
            <Box
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              width="100%"
              sm-margin="0px 0px 16px 0px"
            >
              <Icon
                category="go"
                icon={GoLocation}
                size="32px"
                color="--primary"
                width="40px"
                height="40px"
                md-color="--primary"
                padding="0 0 0 0"
              />
              <Box
                margin="0px 0px 0px 20px"
                width="90%"
                md-margin="0px 0px 0px 20px"
                sm-margin="0px 0px 0px 20px"
              >
                <Text
                  margin="0px 0px 8px 0px"
                  font="--headline4"
                  sm-font="--subheader4"
                  color="--dark"
                  id="4031406592"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4031406592")}
                >
                  {data ? data["4031406592"] : "Our Office"}
                </Text>
                <Text
                  margin="4px 0px 0px 0px"
                  font="--subheader2"
                  width="90%"
                  sm-width="100%"
                  sm-margin="4px 0px 0px 0px"
                  sm-font="--descirption3"
                  color="--grey"
                  id="2877534802"
                  sm-color="--grey"
                  className="hoverText"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["2877534802"]
                      : "1204, Venus towers, Veera Desai Andheri West. 400053.",
                  }}
                  onClick={() => isSideBarOpen("2877534802")}
                ></Text>
              </Box>
            </Box>
            <Box
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              width="100%"
              margin="26px 0px 0px 0px"
              sm-margin="10px 0px 16px 0px"
            >
              <Icon
                category="go"
                icon={GoMail}
                size="32px"
                color="--primary"
                width="40px"
                height="40px"
                md-color="--primary"
                padding="0 0 0 0"
              />
              <Box
                margin="0px 0px 0px 20px"
                md-margin="0px 0px 0px 20px"
                sm-margin="0px 0px 0px 20px"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--headline4"
                  sm-font="--subheader4"
                  color="--dark"
                  id="7249127271"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7249127271")}
                >
                  {data ? data["7249127271"] : "Email Us"}
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  font="--subheader2"
                  sm-margin="4px 0px 0px 0px"
                  sm-font="--descirption3"
                  color="--grey"
                  id="3807399244"
                  sm-color="--grey"
                  className="hoverText"
                  onClick={() => isSideBarOpen("3807399244")}
                >
                  {data ? data["3807399244"] : "jeff@jwra.in"}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              width="100%"
              margin="26px 0px 0px 0px"
              sm-margin="10px 0px 16px 0px"
            >
              <Icon
                category="md"
                icon={MdPhone}
                size="32px"
                color="--primary"
                width="40px"
                height="40px"
                md-color="--primary"
                padding="0 0 0 0"
              />
              <Box margin="0px 0px 0px 20px">
                <Text
                  margin="0px 0px 0px 0px"
                  font="--headline4"
                  sm-font="--subheader4"
                  color="--dark"
                  id="9334953184"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9334953184")}
                >
                  {data ? data["9334953184"] : "Call Us"}
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  font="--subheader2"
                  sm-margin="4px 0px 0px 0px"
                  sm-font="--descirption3"
                  color="--grey"
                  id="5937574466"
                  sm-color="--grey"
                  className="hoverText"
                  onClick={() => isSideBarOpen("5937574466")}
                >
                  {data ? data["5937574466"] : "+91 89762 82122"}
                </Text>
                <Text
                  margin="8px 0px 0px 0px"
                  font="--subheader2"
                  sm-margin="4px 0px 0px 0px"
                  sm-font="--descirption3"
                  color="--grey"
                  id="9609680502"
                  sm-color="--grey"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9609680502")}
                >
                  {data ? data["9609680502"] : "+91 79775 01255"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-height="100px"
            width="50%"
            md-margin="40px 0px 0px 0px"
            sm-margin="14px 0px 0px 0px"
            md-width="100%"
          >
            <form>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                sm-flex-direction="column"
                margin="0px 0px 16px 0px"
              >
                <Box
                  min-width="100px"
                  min-height="100px"
                  width="100%"
                  sm-width="100%"
                  sm-min-height="auto"
                >
                  <Text
                    margin="0px 0px 10px 0px"
                    font="--lead20"
                    sm-margin="0px 0px 6px 0px"
                    sm-font="--descirption3"
                    color="--dark"
                    htmlFor="username"
                    id="0116456608"
                    className="hoverText"
                    onClick={() => isSideBarOpen("0116456608")}
                  >
                    {data ? data["0116456608"] : "First name"}
                  </Text>
                  <Input
                    display="block"
                    placeholder="Enter full name"
                    placeholder-color="Gray"
                    background="white"
                    height="52px"
                    width="96%"
                    border-radius="6px"
                    sm-width="100%"
                    md-width="100%"
                    sm-height="46px"
                    type="text"
                    id="username"
                    name="username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // value={name}
                    value={formik.values.username}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <ErrorComponent error={formik.errors.username} />
                  )}
                  {/* <Text
                  margin="8px 0px 0px 0px"
                  width="92%"
                  id="4687403345"
                  color="--red"
                  className="fsize12"
                >
                  First name must be minimum 3 alphabets
                </Text> */}
                </Box>
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                sm-flex-direction="column"
                sm-margin="10px 0px 0px 0px"
                margin="0px 0px 16px 0px"
              >
                <Box
                  min-width="100px"
                  min-height="100px"
                  width="50%"
                  sm-width="100%"
                  sm-height="fit-content"
                  sm-min-height="auto"
                >
                  <Text
                    margin="0px 0px 10px 0px"
                    font="--lead20"
                    sm-margin="0px 0px 6px 0px"
                    sm-font="--descirption3"
                    color="--dark"
                    htmlFor="phoneNumber"
                    id="2190462338"
                    className="hoverText"
                    onClick={() => isSideBarOpen("2190462338")}
                  >
                    {data ? data["2190462338"] : "Phone no"}
                  </Text>
                  <Input
                    display="block"
                    placeholder="Enter phone number"
                    placeholder-color="Gray"
                    background="white"
                    height="52px"
                    width="92%"
                    border-radius="6px"
                    sm-width="100%"
                    md-width="95%"
                    sm-height="46px"
                    // value={phone}
                    // onChange={handlePhoneChange}
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <ErrorComponent error={formik.errors.phoneNumber} />
                  )}
                  {/* <Text
                  margin="8px 0px 0px 0px"
                  width="92%"
                  id="0051197191"
                  color="--red"
                >
                  Error
                </Text> */}
                </Box>
                <Box
                  min-width="100px"
                  min-height="100px"
                  width="50%"
                  sm-width="100%"
                  sm-height="fit-content"
                  sm-min-height="auto"
                  sm-margin="10px 0px 0px 0px"
                >
                  <Text
                    margin="0px 0px 10px 0px"
                    font="--lead20"
                    sm-margin="0px 0px 6px 0px"
                    sm-font="--descirption3"
                    color="--dark"
                    id="9048355001"
                    className="hoverText"
                    onClick={() => isSideBarOpen("9048355001")}
                  >
                    {data ? data["9048355001"] : "Email id"}
                  </Text>
                  <Input
                    display="block"
                    placeholder="Enter your email"
                    placeholder-color="Gray"
                    background="white"
                    height="52px"
                    width="92%"
                    border-radius="6px"
                    sm-width="100%"
                    md-width="95%"
                    sm-height="46px"
                    // value={email}
                    // onChange={handleEmailChange}
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <ErrorComponent error={formik.errors.email} />
                  )}
                  {/* <Text
                  margin="8px 0px 0px 0px"
                  width="92%"
                  id="8938972644"
                  color="--red"
                >
                  Error
                </Text> */}
                </Box>
              </Box>
              <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                flex-direction="column"
                sm-min-height="auto"
                sm-margin="10px 0px 0px 0px"
              >
                <Text
                  margin="0px 0px 10px 0px"
                  font="--lead20"
                  sm-margin="0px 0px 6px 0px"
                  sm-font="--descirption3"
                  color="--dark"
                  id="3171924341"
                  className="hoverText"
                  onClick={() => isSideBarOpen("3171924341")}
                >
                  {data ? data["3171924341"] : "Message"}
                </Text>
                <Input
                  display="block"
                  placeholder="Enter a message"
                  placeholder-color="Gray"
                  background="white"
                  height="100px"
                  width="96%"
                  border-radius="6px"
                  sm-width="100%"
                  lg-height="70px"
                  md-width="98%"
                  // value={message}
                  // onChange={handleMessageChange}
                  type="message"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message && (
                  <ErrorComponent error={formik.errors.message} />
                )}
                {/* <Text
                margin="8px 0px 0px 0px"
                width="92%"
                id="3616516256"
                color="--red"
              >
                Error
              </Text> */}
              </Box>
              <Box
                display="flex"
                flex-direction="row"
                justify-content="flex-start"
                align-items="center"
                sm-margin="10px 0px 0px 0px"
                margin="10px 0px 0px 0px"
                id="7175641146"
              >
                <Checkbox
                  // onChange={handlecheckChange}
                  size="22px"
                  border-color="--color-lightGrey"
                  color="--greyD1"
                ></Checkbox>

                <Text
                  margin="0px 0px 0px 10px"
                  font="--lead20"
                  color="--dark"
                  height="fit-content"
                  sm-font="--s10R"
                  sm-margin="0px 0px 0px 6px"
                  id="2059388677"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2059388677")}
                >
                  {data ? data["2059388677"] : "I agree to the"}
                </Text>
                <Text
                  margin="0px 0px 0px 4px"
                  font="--lead20"
                  height="fit-content"
                  sm-font="--s12B"
                  color="--primary"
                  id="4295523426"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4295523426")}
                >
                  <Link href="/terms-conditions" color="--primary">
                    {data ? data["4295523426"] : "terms & conditions"}
                  </Link>
                </Text>
              </Box>
              <div
                id="8215739488"
                className="hoverText"
                onClick={() => isSideBarOpen("8215739488")}
              >
                <Text
                  margin="20px 16px 0px 0px"
                  font="--s18R"
                  padding="16px 26px 16px 26px"
                  background="--color-primary"
                  color="#ffffff"
                  border-radius="6px"
                  quarkly-title="Primary btn"
                  sm-font="--s12R"
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  height="72px"
                  letter-spacing="2px"
                  width="96%"
                  lg-margin="20px 16px 0px 0px"
                  sm-width="100%"
                  sm-height="50px"
                  md-width="98%"
                  className="cursor-pointer"
                  onClick={handleSubmit}
                >
                  <Strong
                    sm-font="--s12R"
                    font="--subheader1"
                    color="--lightD1"
                  >
                    {data ? data["8215739488"] : "Submit"}
                  </Strong>
                </Text>
              </div>
            </form>
          </Box>
        </Box>
      </Section>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
