import API from "./index";

const create = "createContact";

const apiBase = (route) => {
  return `/custom/contactUs/${route}`;
};

export default {
  createEnquireContact(payload) {
    return API.post(apiBase(create), payload);
  },
};
