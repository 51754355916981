export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6503f96091dc650018455a59",
				"6503f96091dc650018455a5d",
				"6503f96091dc650018455a61",
				"6503f96091dc650018455a65",
				"6503f96091dc650018455a6d",
				"6503f96091dc650018455a75",
				"6503f96091dc650018455a69",
				"650d3f3472a6d30018292442",
				"650daf85477c3a0019b8badc"
			]
		},
		"6503f96091dc650018455a59": {
			"id": "6503f96091dc650018455a59",
			"name": "404",
			"pageUrl": "404"
		},
		"6503f96091dc650018455a5d": {
			"id": "6503f96091dc650018455a5d",
			"name": "index",
			"pageUrl": "index"
		},
		"6503f96091dc650018455a61": {
			"id": "6503f96091dc650018455a61",
			"pageUrl": "index1",
			"name": "About us"
		},
		"6503f96091dc650018455a65": {
			"id": "6503f96091dc650018455a65",
			"pageUrl": "product-overview",
			"name": "Gallery"
		},
		"6503f96091dc650018455a69": {
			"id": "6503f96091dc650018455a69",
			"pageUrl": "contact",
			"name": "Contact"
		},
		"6503f96091dc650018455a6d": {
			"id": "6503f96091dc650018455a6d",
			"pageUrl": "index111",
			"name": "Architect portfolio"
		},
		"6503f96091dc650018455a75": {
			"id": "6503f96091dc650018455a75",
			"pageUrl": "index1112",
			"name": "Interior Designer portfolio"
		},
		"650d3f3472a6d30018292442": {
			"pageUrl": "contact1",
			"id": "650d3f3472a6d30018292442",
			"name": "Terms & Conditions"
		},
		"650daf85477c3a0019b8badc": {
			"pageUrl": "contact12",
			"id": "650daf85477c3a0019b8badc",
			"name": "Privacy Policy"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}