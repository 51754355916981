import API from "./index";

const get = "get";

const categoryRoute = (route) => {
  return `baseAdminCategory/${route}`;
};
const subCategoryRoute = (route) => {
  return `baseAdminSubCategory/${route}`;
};
const subCategoryByCategoryRoute = (route) => {
  return `portfolio/subcategory/${route}`;
};
const productBysubcatRoute = (route) => {
  return `portfolio/product/${route}`;
};
export default {
  getAllCategory(payload) {
    return API.post(categoryRoute(get), payload);
  },
  getAllSubCategory(payload) {
    return API.post(subCategoryRoute(get),payload);
  },
  getSubCategory(payload) {
    return API.post(subCategoryByCategoryRoute(get), payload);
  },
  getProduct(payload) {
    return API.post(productBysubcatRoute(get), payload);
  },
};
