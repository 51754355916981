import API from "./index";

const create = "create";

const apiBase = (route) => {
  return `/enquireForm/${route}`;
};


export default {
  create(payload) {
    return API.post(apiBase(create), payload);
  },
};
