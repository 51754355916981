import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import theme from "theme";
import {
  Theme,
  Text,
  Icon,
  Button,
  Box,
  Section,
  Image,
  LinkBox,
  Hr,
  Input,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import { MdArrowForward } from "react-icons/md";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
// import { createEnqireContactUs } from "redux/contact/contact-action";
import { createEnqForm } from "redux/enquireForm/marcom-action";
import { getSubCategory, getAllCategory } from "redux/Product/product-action";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  username: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, "Only alphabetic characters allowed")
    .min(2, "Full name must be at least 2 character")
    .max(50, "Full name must be 50 characters or less")
    .required("Full name is required"),
  companyname: Yup.string()
    .trim()
    .matches(/^[^\s]+(\s[^\s]+)*[^\s]$/, "Invalid Format")
    .min(2, "Full name must be at least 2 character")
    .max(50, "Full name must be 50 characters or less")
    .required("Company Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter valid phone number")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Please enter valid email address")
    .required("Email id is required"),
  message: Yup.string().required("Message is required"),
});

const ErrorComponent = ({ error }) => (
  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
);
export default () => {
  const formik = useFormik({
    initialValues: {
      username: "",
      companyname: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      name: formik.values.username.trim(),
      companyName: formik.values.companyname.trim(),
      mobile: formik.values.phoneNumber.trim(),
      email: formik.values.email.trim(),
      description: formik.values.message.trim(),
    };

    if (
      payload.name !== "" &&
      payload.companyName !== "" &&
      payload.mobile !== "" &&
      payload.email !== "" &&
      payload.description !== "" &&
      Object.keys(formik.errors).length === 0
    ) {
      let data = await dispatch(createEnqForm(payload));
      // console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Congratulations",
          text: "Your query has been successfully sent.",
          icon: "success",
        });
        // console.log(data.code, "registerCode");
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fill all the required details",
        icon: "error",
      });
      // console.log(data.message, "datamessage");
    }
  };

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  // const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  // const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  // let AllCategory = useSelector((state) => state.product.Category);
  let categoryData = useSelector((state) => state.product.addSingleSubCategory);

  // console.log(categoryData.result, "NEWWWWW");
  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["Home"]);

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------
  function getImage(img) {
    return process.env.REACT_APP_STORAGE_URL_2 + img;
  }
  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(getSubCategory());
  }, [dispatch]);
  // console.log(AllCategory, "outside1");

  // console.log(data, "outside");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      // console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    // console.log(selectedData, "show");
  }

  // const isShowSubCategory = (data) => {
  //   console.log(data, "logg");
  //   dispatch(addSingleSubCategory(data.subcategoryId));
  //   let defData = data.subcategoryId;

  //   console.log(defData, "checkdatasimgle");
  // };
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"home"} />
      <Helmet>
        <title>Home</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <div
        className="relative"
        // background="linear-gradient(0deg,rgba(255, 255, 255, 0.05) 0%,rgba(255, 255, 255, 0.44) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z) top/cover repeat scroll padding-box"
      >
        {/* <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          align-items="center"
          justify-content="space-between"
          padding="60px 0px 0px 0px"
          flex-direction="row"
        /> */}
        <Box
          width="100%"
          id="7564312260"
          className="hoverText"
          onClick={() => isSideBarOpen("7564312260")}
        >
          <img
            src={
              data
                ? data["7564312260"]
                : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
            }
            alt="banner"
            className="h-600px object-cover w-full linear"
          />
        </Box>
        <Section padding="0px" min-height="0px">
          <div className="absolute top-0  flex items-center h-600px ">
            <Box width="80%" lg-width="80%" md-width="94%" sm-width="100%">
              <Text
                margin="0px 0px 16px 0px"
                font="--headline1"
                color="--darkL2"
                md-font="--headline2"
                sm-font="--headline3"
                id="8987589953"
                className="hoverText"
                onClick={() => isSideBarOpen("8987589953")}
              >
                {data ? data["8987589953"] : "Where Vision Meets Reality"}
              </Text>
              <Text
                margin="0px 0px 40px 0px"
                font="--subheader3"
                color="--darkL2"
                md-font="--descirption3"
                sm-font="--descirption3"
                id="4035501044"
                className="hoverText"
                onClick={() => isSideBarOpen("4035501044")}
              >
                {data ? data["4035501044"] : "Empowering Innovation Through"}
              </Text>
              <div
                id="7518781883"
                className="hoverText"
                onClick={() => isSideBarOpen("7518781883")}
              >
                <Button
                  font="--button1"
                  type="link"
                  href="/gallery"
                  color="--darkL2"
                  width="fit-content"
                  padding="14px 24px 14px 24px"
                  md-padding="7px 16px 7px 16px"
                  background="--color-white"
                  display="flex"
                  align-items="center"
                  border-radius="8px"
                  margin="20px 0px 0px 0px"
                >
                  {data ? data["7518781883"] : "View Work"}
                  <Icon
                    category="md"
                    icon={MdArrowForward}
                    margin="0px 0px 0px 10px"
                  />
                </Button>
              </div>
            </Box>
          </div>
        </Section>
      </div>

      <Section padding="50px 0 50px 0">
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="row-reverse"
          align-items="center"
          sm-flex-direction="column-reverse"
          md-flex-direction="column-reverse"
          justify-content="flex-end"
        />{" "}
        <Box width="65%" lg-width="100%">
          <Swiper
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className=" mySwiper"
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.8,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 1.8,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 1.8,
                spaceBetween: 30,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              820: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className=" swiper-button  image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className=" swiper-button  image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {categoryData &&
            categoryData.result &&
            categoryData.result.length > 0
              ? categoryData.result.map((e, i) => (
                  <SwiperSlide key={i}>
                    <LinkBox
                      align-items="flex-start"
                      sm-width="100%"
                      margin="0px 0px 20px 0px"
                      md-margin="0px 0px 0 0px"
                      href="/Gallery"
                    >
                      <Box
                        margin="0px 0px 0px 0px"
                        position="relative"
                        sm-width="100%"
                        width="100%"

                        // onClick={() => isSideBarOpen("4217717383")}
                      >
                        <Image
                          src={
                            e.image && e.image.length > 0
                              ? getImage(e.image)
                              : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/043.png?v=2023-09-13T13:51:49.024Z"
                          }
                          display="block"
                          height="330px"
                          object-fit="cover"
                          sm-height="260px"
                          width="100%"
                          sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                          alt="INTERIOR"
                        />
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        justify-content="space-between"
                        align-items="center"
                        margin="6px 0px 0px 0px"
                      >
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--descirption3"
                          margin="0px 0px 0px 0px"

                          // onClick={() => isSideBarOpen("9735287218")}
                        >
                          {/* {data ? data["9735287218"] : "ARCHITECTURE"} */}
                          {e.name.toUpperCase()}
                        </Text>
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--subheader1"
                          margin="0px 0px 0px 0px"
                        >
                          🡭
                        </Text>
                      </Box>
                      <Text
                        margin="0px 0px 15px 0px"
                        font="--subheader"
                        display="none"
                      >
                        Corporate Services
                      </Text>
                    </LinkBox>
                  </SwiperSlide>
                ))
              : "no-data"}
          </Swiper>
        </Box>
        <Box
          width="35%"
          margin="0px 15px 0px 0px"
          md-width="100%"
          md-margin="0px 0px 40px 0px"
        >
          <Text
            margin="0px 0px 42px 0px"
            font="--headline2"
            color="--darkL2"
            text-align="left"
            sm-font="--heading"
            sm-margin="0px 0px 16px 0px"
            md-margin="0px 0px 32px 0px"
            id="3206721091"
            className="hoverText"
            onClick={() => isSideBarOpen("3206721091")}
          >
            {data
              ? data["3206721091"]
              : "Architectural Excellence & Interior Elegance"}
          </Text>
          <Text
            margin="0px 0px 70px 0px"
            font="--subheader2"
            color="--grey"
            text-align="left"
            sm-font="--descirption3"
            letter-spacing="0.5px"
            md-margin="0px 0px 0 0px"
            sm-color="--grey"
            id="6644934615"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["6644934615"]
                : "Our architectural firm is built on a foundation of creativity",
            }}
            onClick={() => isSideBarOpen("6644934615")}
          ></Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="0px 0 40px 0"
        quarkly-title="Images-1"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          margin="0px auto 0px auto"
          sm-flex-direction="column"
        />
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="center"
          lg-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
            id="8002985696"
            className="hoverText"
            onClick={() => isSideBarOpen("8002985696")}
          >
            <Image
              src={
                data
                  ? data["8002985696"]
                  : "https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              }
              position="absolute"
              display="block"
              width="100%"
              left={0}
              min-height="100%"
              object-fit="cover"
              top={0}
              right={0}
              bottom={0}
              alt="image"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          flex-direction="column"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          md-padding="16px 16px 16px 16px"
        >
          <Box
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            id="8078572394"
            className="hoverText"
            onClick={() => isSideBarOpen("8078572394")}
          >
            <Image
              bottom={0}
              src={
                data
                  ? data["8078572394"]
                  : "https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=434&q=80"
              }
              top={0}
              display="block"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
              id="1525723193"
              alt="image"
              // srcSet="https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1566195992011-5f6b21e539aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3200&q=80 3200w"
              sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
            />
          </Box>
          <Text
            margin="0px 0px 70px 0px"
            font="--subheader2"
            color="--darkL2"
            text-align="left"
            sm-font="--descirption3"
            sm-margin="0px 0px 0px 0px"
            letter-spacing="0.5px"
            padding="20px 0px 0px 0px"
            md-margin="0px 0px 16px 0px"
            id="4304934510"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["4304934510"]
                : "Our portfolio showcases our dedication to excellence",
            }}
            onClick={() => isSideBarOpen("4304934510")}
          ></Text>
        </Box>
      </Section>
      <Section
        sm-padding="30px 0 30px 0"
        lg-padding="10px 0 60px 0"
        padding="10px 0 100px 0"
        quarkly-title="Statistics-12"
        background="linear-gradient(0deg,#ffffff 0%,rgba(255, 255, 255, 0.17) 18.2%,rgba(249, 249, 249, 0.37) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1487958449943-2429e8be8625?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 50% 0% /cover repeat scroll padding-box"
        align-items="center"
        justify-content="center"
        id="1808203997"
      >
        <Override
          slot="SectionContent"
          max-width="1440px"
          align-items="flex-start"
          flex-direction="column"
          flex-wrap="wrap"
          margin="50px 72px 0px 72px"
          md-margin="50px auto 0px auto"
          sm-margin="0px auto 0px auto"
        />
        <Box width="100%" sm-width="100%" />
        <Box
          width="100%"
          sm-width="100%"
          border-width="0px 0px 1px 0px"
          border-style="solid"
          border-color="--color-grey"
        >
          <Text
            margin="0px 0px 20px 0px"
            font="--lead"
            color="--dark"
            sm-margin="0px 0px 10px 0px"
            sm-font="--base"
            id="1411364383"
            className="hoverText"
            onClick={() => isSideBarOpen("1411364383")}
          >
            {data ? data["1411364383"] : "Architectural Services"}
          </Text>
        </Box>
        <Box
          width="100%"
          sm-width="100%"
          margin="50px 0px 0px 0px"
          md-width="100%"
          padding="0px 30px 0px 0px"
          lg-margin="50px 0px 0px 0px"
          sm-margin="20px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 20px 0px"
            font="--subheader3"
            color="--dark"
            sm-margin="0px 0px 20px 0px"
            lg-color="--darkL2"
            md-font="--subheader2"
            sm-font="--descirption3"
            id="3522903670"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["3522903670"]
                : "Our comprehensive architectural services",
            }}
            onClick={() => isSideBarOpen("3522903670")}
          ></Text>
        </Box>
        <Box
          width="100%"
          md-width="100%"
          display="flex"
          align-items="center"
          background="--color-white"
          padding="28px 0px 28px 0px"
          border-radius="20px"
          box-shadow="0 0 40px 20px rgba(0, 0, 0, 0.13)"
          margin="30px 0px 0px 0px"
          sm-margin="20px 0px 0px 0px"
        >
          <Box
            width="100%"
            sm-width="100%"
            sm-padding="0px 0px 0px 0px"
            display="flex"
            align-items="center"
            justify-content="center"
            padding="0px 0px 0px 6px"
            font="--description1"
            color="--white"
            margin="0px 24px 0px 0px"
            border-width="0 2px 0  0"
            border-color="#b1b1b1"
            border-style="solid"
            flex-direction="column"
          >
            <Text
              color="--dark"
              sm-margin="0px 0px 10px 0px"
              letter-spacing="-.06em"
              lg-font="--headline2"
              font="--headline1"
              margin="0px 0px 0px 0px"
              sm-font="--headline3"
              id="1714637981"
              className="hoverText"
              onClick={() => isSideBarOpen("1714637981")}
            >
              {data ? data["1714637981"] : "18"}
            </Text>
            <Text
              color="--dark"
              sm-margin="0px 0px 0px 0px"
              letter-spacing="-.06em"
              lg-font="--description1"
              font="--subheader3"
              lg-color="--darkL2"
              sm-font="--descirption3"
              sm-text-align="center"
              id="1087904869"
              className="hoverText"
              onClick={() => isSideBarOpen("1087904869")}
            >
              {data ? data["1087904869"] : "Interiors Projects"}
            </Text>
            <Hr margin="0px 0px 0px 0px" />
          </Box>
          <Box
            width="100%"
            sm-width="100%"
            sm-padding="0px 0px 0px 0px"
            display="flex"
            align-items="center"
            justify-content="center"
            padding="0px 0px 0px 6px"
            font="--description1"
            color="--white"
            margin="0px 24px 0px 0px"
            border-width="0 2px 0  0"
            border-color="#b1b1b1"
            border-style="solid"
            flex-direction="column"
          >
            <Text
              color="--dark"
              sm-margin="0px 0px 10px 0px"
              letter-spacing="-.06em"
              lg-font="--headline2"
              font="--headline1"
              margin="0px 0px 0px 0px"
              sm-font="--headline3"
              id="3593380062"
              className="hoverText"
              onClick={() => isSideBarOpen("3593380062")}
            >
              {data ? data["3593380062"] : "8"}
            </Text>
            <Text
              color="--dark"
              sm-margin="0px 0px 0px 0px"
              letter-spacing="-.06em"
              lg-font="--description1"
              font="--subheader3"
              lg-color="--darkL2"
              sm-font="--descirption3"
              sm-text-align="center"
              id="3564556792"
              className="hoverText"
              onClick={() => isSideBarOpen("3564556792")}
            >
              {data ? data["3564556792"] : "Buildings Completed"}
            </Text>
            <Hr margin="0px 0px 0px 0px" />
          </Box>
          <Box
            width="100%"
            sm-width="100%"
            sm-padding="0px 0px 0px 0px"
            display="flex"
            align-items="center"
            justify-content="center"
            padding="0px 0px 0px 6px"
            font="--description1"
            color="--white"
            flex-direction="column"
          >
            <Text
              font="--headline1"
              color="--dark"
              sm-margin="0px 0px 10px 0px"
              letter-spacing="-.06em"
              lg-font="--headline2"
              margin="0px 0px 0px 0px"
              sm-font="--headline3"
              id="2328506090"
              className="hoverText"
              onClick={() => isSideBarOpen("2328506090")}
            >
              {data ? data["2328506090"] : "4"}
            </Text>
            <Text
              font="--subheader3"
              color="--dark"
              sm-margin="0px 0px 0px 0px"
              letter-spacing="-.06em"
              lg-font="--description1"
              margin="16px 0px 16px 0px"
              lg-color="--darkL2"
              sm-font="--descirption3"
              id="9553036671"
              className="hoverText"
              onClick={() => isSideBarOpen("9553036671")}
            >
              {data ? data["9553036671"] : "States"}
            </Text>
            <Hr margin="0px 0px 0px 0px" />
          </Box>
        </Box>
      </Section>
      {/* <Section
        padding="50px 0 40px 0"
        overflow-x="hidden"
        md-padding="50px 0 24px 0"
      >
        <Override slot="SectionContent" margin="0px auto 0px auto" />
        <Text
          margin="0px 0px 42px 0px"
          font="--headline2"
          color="--darkL2"
          text-align="left"
          sm-font="--heading"
          sm-margin="0px 0px 40px 0px"
          md-font="--headline3"
          sm-width="fit-content"
          id="4927555004"
          className="hoverText"
          onClick={() => isSideBarOpen("4927555004")}
        >
          {data ? data["4927555004"] : "A Glimpse into Our Creative Portfolio"}
        </Text>
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="gutter mySwiper container relative lg:mx-auto md:mx-5  overflow-x-hidden"
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          loop
          breakpoints={{
            1536: {
              slidesPerView: 3.8,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3.8,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2.8,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className=" swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <LinkBox align-items="flex-start" margin="0px 0px 20px 0px">
              <Box width="100%" margin="0px 0px 0px 0px" position="relative">
                <Box
                  id="0799253999"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0799253999")}
                >
                  <Image
                    src={
                      data
                        ? data["0799253999"]
                        : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                    }
                    display="block"
                    object-fit="cover"
                    sm-height="260px"
                    height="470px"
                    width="100%"
                    alt="image"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                  />
                </Box>
                <Box
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0px"
                  right="0px"
                  bottom="auto"
                  left="auto"
                  opacity="0"
                  hover-opacity="1"
                  transition="opacity 0.4s ease-in-out 0s"
                  background="rgba(0, 0, 0, 0.18)"
                  hover-background="rgba(0, 0, 0, 0.12)"
                />
              </Box>
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="8px 0px 0px 0px"
              >
                <Text
                  font="--heading"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--descirption3"
                  margin="0px 0px 0px 0px"
                  sm-margin="0px 60px 0px 0px"
                  sm-width="max-content"
                  id="0260336385"
                  className="hoverText"
                  onClick={() => isSideBarOpen("0260336385")}
                >
                  {data ? data["0260336385"] : "innovative design"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text margin="0px 0px 15px 0px" font="--subheader" display="none">
                Corporate Services
              </Text>
            </LinkBox>
          </SwiperSlide>
          <SwiperSlide>
            <LinkBox align-items="flex-start" margin="0px 0px 20px 0px">
              <Box
                margin="0px 0px 0px 0px"
                position="relative"
                width="100%"
                id="5989047235"
                className="hoverText"
                onClick={() => isSideBarOpen("5989047235")}
              >
                <Image
                  src={
                    data
                      ? data["5989047235"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                  }
                  display="block"
                  object-fit="cover"
                  sm-height="260px"
                  height="470px"
                  width="100%"
                  alt="image"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Box
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0px"
                  right="0px"
                  bottom="auto"
                  left="auto"
                  hover-opacity="1"
                  transition="opacity 0.4s ease-in-out 0s"
                  background="rgba(0, 0, 0, 0.18)"
                  hover-background="rgba(0, 0, 0, 0.12)"
                  opacity="0"
                />
              </Box>
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="8px 0px 0px 0px"
              >
                <Text
                  font="--heading"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--descirption3"
                  margin="0px 0px 0px 0px"
                  sm-margin="0px 60px 0px 0px"
                  id="6082580619"
                  className="hoverText"
                  onClick={() => isSideBarOpen("6082580619")}
                >
                  {data ? data["6082580619"] : "functionality"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text margin="0px 0px 15px 0px" font="--subheader" display="none">
                Corporate Services
              </Text>
            </LinkBox>
          </SwiperSlide>
          <SwiperSlide>
            <LinkBox align-items="flex-start" margin="0px 0px 20px 0px">
              <Box
                width="100%"
                margin="0px 0px 0px 0px"
                position="relative"
                id="9213544103"
                className="hoverText"
                onClick={() => isSideBarOpen("9213544103")}
              >
                <Image
                  src={
                    data
                      ? data["9213544103"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                  }
                  display="block"
                  object-fit="cover"
                  sm-height="260px"
                  height="470px"
                  width="100%"
                  alt="image"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Box
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0px"
                  right="0px"
                  bottom="auto"
                  left="auto"
                  hover-opacity="1"
                  transition="opacity 0.4s ease-in-out 0s"
                  background="rgba(0, 0, 0, 0.18)"
                  hover-background="rgba(0, 0, 0, 0.12)"
                  opacity="0"
                />
              </Box>
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="8px 0px 0px 0px"
              >
                <Text
                  font="--heading"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--descirption3"
                  margin="0px 0px 0px 0px"
                  sm-margin="0px 60px 0px 0px"
                  id="6279623170"
                  className="hoverText"
                  onClick={() => isSideBarOpen("6279623170")}
                >
                  {data ? data["6279623170"] : "sustainability"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text margin="0px 0px 15px 0px" font="--subheader" display="none">
                Corporate Services
              </Text>
            </LinkBox>
          </SwiperSlide>
          <SwiperSlide>
            <LinkBox align-items="flex-start" margin="0px 0px 20px 0px">
              <Box
                width="100%"
                margin="0px 0px 0px 0px"
                position="relative"
                id="8876216267"
                className="hoverText"
                onClick={() => isSideBarOpen("8876216267")}
              >
                <Image
                  src={
                    data
                      ? data["8876216267"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07:28:29.473Z"
                  }
                  display="block"
                  object-fit="cover"
                  sm-height="260px"
                  height="470px"
                  width="100%"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/01.jpg?v=2023-09-04T07%3A28%3A29.473Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
                <Box
                  display="flex"
                  align-items="center"
                  justify-content="center"
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="0px"
                  right="0px"
                  bottom="auto"
                  left="auto"
                  hover-opacity="1"
                  transition="opacity 0.4s ease-in-out 0s"
                  background="rgba(0, 0, 0, 0.18)"
                  hover-background="rgba(0, 0, 0, 0.12)"
                  opacity="0"
                />
              </Box>
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="8px 0px 0px 0px"
              >
                <Text
                  font="--heading"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--descirption3"
                  margin="0px 0px 0px 0px"
                  sm-margin="0px 00px 0px 0px"
                  id="3130771049"
                  className="hoverText"
                  onClick={() => isSideBarOpen("3130771049")}
                >
                  {data ? data["3130771049"] : "commercial development"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text margin="0px 0px 15px 0px" font="--subheader" display="none">
                Corporate Services
              </Text>
            </LinkBox>
          </SwiperSlide>
        </Swiper>
      </Section> */}
      <Section
        padding="40px 0 40px 0"
        sm-padding="24px 0px 24px 0px"
        lg-align-items="center"
        lg-justify-content="center"
        align-items="center"
        align-content="center"
        justify-content="center"
        position="relative"
        margin="0 auto 0 auto"
        md-padding="24px 0px 40px 0px"
        lg-padding="50px 0 10px 0"
      >
        <Override
          slot="SectionContent"
          flex-wrap="wrap"
          margin="0px auto 0px auto"
        />
        <Box
          display="flex"
          flex-direction="column"
          justify-content="flex-start"
          align-items="center"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 0px 0px"
          padding="10px 0px 0px 0px"
          lg-flex-direction="column"
          lg-flex-wrap="wrap"
          lg-display="flex"
          margin="0px 0px 0px 0px"
          sm-align-items="center"
          sm-justify-content="center"
          sm-padding="0px 0px 0px 0px"
          md-margin="0px 0px 0 0px"
        >
          <Text
            margin="0px 0px 30px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--heading"
            lg-width="100%"
            id="9962317563"
            text-align="center"
            md-margin="0px 0px 16px 0px"
            className="hoverText"
            md-font="--headline3"
            sm-text-align="left"
            onClick={() => isSideBarOpen("9962317563")}
          >
            {data ? data["9962317563"] : "See Our Insta Feed"}
          </Text>
          {/* <Box
            width="100%"
            display="grid"
            grid-template-columns="repeat(3, 1fr)"
            grid-gap="20px"
            margin="0px 0px 36px 0px"
            md-grid-template-columns="repeat(2, 1fr)"
            sm-grid-gap="10px"
            md-margin="0px 0px 0px 0px"
          >
            <div
              id="2560617450"
              className="hoverText"
              onClick={() => isSideBarOpen("2560617450")}
            >
              <Image
                src={
                  data
                    ? data["2560617450"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
            <div
              id="7545347381"
              className="hoverText"
              onClick={() => isSideBarOpen("7545347381")}
            >
              <Image
                src={
                  data
                    ? data["7545347381"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
            <div
              id="8728907929"
              className="hoverText"
              onClick={() => isSideBarOpen("8728907929")}
            >
              <Image
                src={
                  data
                    ? data["8728907929"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
            <div
              id="1414364533"
              className="hoverText"
              onClick={() => isSideBarOpen("1414364533")}
            >
              <Image
                src={
                  data
                    ? data["1414364533"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
            <div
              id="8847969985"
              className="hoverText"
              onClick={() => isSideBarOpen("8847969985")}
            >
              <Image
                src={
                  data
                    ? data["8847969985"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
            <div
              id="0934104711"
              className="hoverText"
              onClick={() => isSideBarOpen("0934104711")}
            >
              <Image
                src={
                  data
                    ? data["0934104711"]
                    : "https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                }
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                sm-height="200px"
                alt="image"
                // srcSet="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </div>
          </Box> */}
          <iframe
            src="https://widgets.sociablekit.com/instagram-feed/iframe/253206"
            frameborder="0"
            width="100%"
            height="1000"
            title="instagram"
          ></iframe>
        </Box>
      </Section>
      <Section
        padding="32px 0 32px 0"
        lg-padding="50px 0 50px 0"
        sm-padding="24px 0 24px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          align-items="center"
        />
        <Text
          margin="0px 0px 70px 0px"
          font="--headline2"
          color="--darkL2"
          text-align="center"
          sm-font="--heading"
          sm-margin="0px 0px 24px 0px"
          sm-text-align="left"
          id="2673340029"
          className="hoverText"
          onClick={() => isSideBarOpen("2673340029")}
        >
          {data ? data["2673340029"] : "Enquire now and get best solution"}
        </Text>
        <Box
          width="70%"
          display="flex"
          flex-direction="column"
          sm-width="100%"
          md-width="100%"
          lg-width="100%"
        >
          <form>
            <Box
              display="flex"
              margin="0px 0px 20px 0px"
              sm-flex-direction="column"
            >
              <Box
                width="50%"
                margin="0px 22px 0px 0px"
                sm-width="100%"
                sm-margin="0px 22px 10px 0px"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="9912001306"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9912001306")}
                >
                  {data ? data["9912001306"] : "Your name"}
                </Text>
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  background="white"
                  padding="16px 16px 16px 16px"
                  placeholder="Enter your name"
                  width="100%"
                  border-color="rgba(132, 132, 132, 0.86)"
                  type="text"
                  id="username"
                  name="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username && (
                  <ErrorComponent error={formik.errors.username} />
                )}
              </Box>
              <Box width="50%" sm-width="100%">
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="2639781388"
                  className="hoverText"
                  onClick={() => isSideBarOpen("2639781388")}
                >
                  {data ? data["2639781388"] : "Company Name"}
                </Text>
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  background="white"
                  padding="16px 16px 16px 16px"
                  placeholder="Enter company name"
                  width="100%"
                  border-color="rgba(132, 132, 132, 0.86)"
                  type="text"
                  id="companyname"
                  name="companyname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyname}
                />
                {formik.touched.companyname && formik.errors.companyname && (
                  <ErrorComponent error={formik.errors.companyname} />
                )}
              </Box>
            </Box>
            <Box
              display="flex"
              margin="0px 0px 20px 0px"
              sm-flex-direction="column"
            >
              <Box
                width="50%"
                margin="0px 22px 0px 0px"
                sm-margin="0px 0px 10px 0px"
                sm-width="100%"
              >
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="9721892238"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9721892238")}
                >
                  {data ? data["9721892238"] : "Phone number"}
                </Text>
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  background="white"
                  padding="16px 16px 16px 16px"
                  placeholder="Enter phone number"
                  width="100%"
                  border-color="rgba(132, 132, 132, 0.86)"
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <ErrorComponent error={formik.errors.phoneNumber} />
                )}
              </Box>
              <Box width="50%" sm-width="100%">
                <Text
                  margin="0px 0px 6px 0px"
                  font="--descirption3"
                  color="--darkL2"
                  text-align="left"
                  id="7731597900"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7731597900")}
                >
                  {data ? data["7731597900"] : "Email"}
                </Text>
                <Input
                  display="block"
                  placeholder-color="LightGray"
                  background="white"
                  padding="16px 16px 16px 16px"
                  placeholder="Enter your email"
                  width="100%"
                  border-color="rgba(132, 132, 132, 0.86)"
                  type="email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <ErrorComponent error={formik.errors.email} />
                )}
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              margin="0px 0px 20px 0px"
            >
              <Text
                margin="0px 0px 6px 0px"
                font="--descirption3"
                color="--darkL2"
                text-align="left"
                id="8697456034"
                className="hoverText"
                onClick={() => isSideBarOpen("8697456034")}
              >
                {data ? data["8697456034"] : "Message"}
              </Text>
              <Input
                display="flex"
                placeholder-color="LightGray"
                background="white"
                padding="16px 16px 16px 16px"
                placeholder="Enter a message"
                width="100%"
                height="90px"
                align-items="flex-start"
                placeholder-justify-content="flex-start"
                placeholder-align-items="flex-start"
                sm-padding="0px 16px 0px 16px"
                sm-height="180px"
                sm-justify-content="flex-start"
                border-color="rgba(132, 132, 132, 0.86)"
                type="message"
                id="message"
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              {formik.touched.message && formik.errors.message && (
                <ErrorComponent error={formik.errors.message} />
              )}
            </Box>
            <div
              id="9137344280"
              className="hoverText"
              onClick={() => isSideBarOpen("9137344280")}
            >
              <Box
                align-items="center"
                display="flex"
                justify-content="center"
                onClick={handleSubmit}
              >
                <Button
                  font="--button1"
                  color="--white"
                  background="--color-primary"
                  border-radius="0px"
                  margin="0px 0px 0px 0px"
                  padding="16px 24px 16px 24px"
                  width="100%"
                >
                  {data ? data["9137344280"] : "Submit"}
                </Button>
              </Box>
            </div>
          </form>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
