import React, { useEffect, useState } from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { setSelectedData } from "redux/CMS/cms-actions";
import ContentUpdate from "components/CMS/ContentUpdate";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";
import {
  getSubCategory,
  selectedPortfolio,
} from "redux/Product/product-action";
export default () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);

  const [activePage, setActivePage] = useState(1);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  // let AllCategory = useSelector((state) => state.product.Category);
  let categoryData = useSelector((state) => state.product.addSingleSubCategory);
  // console.log(categoryData.result, "NNNNNNNN");
  // console.log(BrandDetails, "outside in");

  let data = useSelector((state) => state.cms.pages["Gallery"]);

  // function getImage(img) {
  //   return process.env.REACT_APP_STORAGE_URL_2 + img;
  // }
  useEffect(() => {
    dispatch(
      getSubCategory({
        limit: 10,
        offset: activePage > 1 ? ([activePage] - 1) * 10 : 0,
      })
    );
  }, [dispatch, activePage]);

  // console.log(AllCategory, "outside1");
  //-----------hide/show-------
  const { token } = useParams();

  // const isShowSubCategory = (data) => {
  //   console.log(data, "logg");
  //   dispatch(addSingleSubCategory(data.subcategoryId));
  //   let defData = data.subcategoryId;

  //   console.log(defData, "checkdatasimgle");
  // };
  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }
  const handlePage = async (page) => {
    setActivePage(page);
    let loadData = await dispatch(
      getSubCategory({ limit: 10, offset: page > 1 ? ([page] - 1) * 10 : 0 })
    );
    if (loadData.code === 200) {
      // setLoader(false);
    }
    window.scroll(0, 0);
  };
  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.favIcon
        : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  // const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"product-overview"} />
      <Helmet>
        <title>Gallery</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        padding="0px 0 0px 0"
        background="linear-gradient(0deg,rgba(255, 255, 255, 0.9) 0%,rgba(255, 255, 255, 0.9) 100%),rgba(0, 0, 0, 0.4) url(https://images.unsplash.com/photo-1652667930160-002c7eebb68e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 50% 30% /auto repeat scroll padding-box"
        height="400px"
        align-items="center"
        justify-content="center"
        sm-height="max-content"
      >
        <Override
          slot="SectionContent"
          margin="0px 0px 0px 0px"
          max-width="100%"
          justify-content="center"
          align-items="center"
          sm-margin="40px 0px 40px 0px"
        />
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 20px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
          color="--lightD1"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL1"
            font="--headline2"
            lg-text-align="center"
            sm-font="--heading"
            padding="0px 16px 0px 0px"
            width="100%"
            id="5482341284"
            className="hoverText"
            onClick={() => isSideBarOpen("5482341284")}
          >
            {data
              ? data["5482341284"]
              : "Our mission is to make your life easier."}
          </Text>
        </Box>
        <Box
          display="flex"
          width="100%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 0px 0px"
          margin="24px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL1"
            font="--subheader2"
            lg-text-align="center"
            display="flex"
            align-items="flex-start"
            width="100%"
            sm-font="--descirption3"
            id="2758924470"
            className="hoverText"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["2758924470"]
                : "Our mission is to bring your architectural visions to life",
            }}
            onClick={() => isSideBarOpen("2758924470")}
          ></Text>
        </Box>
      </Section>
      {/* <Section
        padding="80px 0 80px 0"
        sm-padding="40px 0 40px 0"
        md-padding="40px 0 80px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
        />
        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          width="100%"
        >
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="1 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
     
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
            >
              <Box
                id="4485550453"
                className="hoverText"
                onClick={() => isSideBarOpen("4485550453")}
              >
                <Image
                  src={
                    data
                      ? data["4485550453"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13:47:59.860Z"
                  }
                  border-radius={0}
                  max-width="100%"
                  width="100%"
                  object-fit="cover"
                  lg-max-height="392px"
                  object-position="50% 50%"
                  sm-max-height="213px"
                  height="522px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/02%20EDIT.png?v=2023-09-13T13%3A47%3A59.860Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                display="flex"
                align-items="center"
                justify-content="center"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                right="0px"
                bottom="auto"
                left="auto"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.4s ease-in-out 0s"
                background="rgba(0, 0, 0, 0.12)"
                hover-background="rgba(0, 0, 0, 0.12)"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="7653120775"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7653120775")}
                >
                  {data ? data["7653120775"] : "INTERIOR DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="2179533433"
                className="hoverText"
                onClick={() => isSideBarOpen("2179533433")}
              >
                {data ? data["2179533433"] : "Residential Design"}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="2 / span 2"
            lg-grid-column="2 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
            href="architect-portolio"
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
            >
              <Box
                id="1425288238"
                className="hoverText"
                width="100%"
                onClick={() => isSideBarOpen("1425288238")}
              >
                <Image
                  src={
                    data
                      ? data["1425288238"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/043.png?v=2023-09-13T13:51:49.024Z"
                  }
                  border-radius={0}
                  max-width="100%"
                  width="100%"
                  object-fit="cover"
                  lg-max-height="392px"
                  object-position="50% 50%"
                  sm-max-height="213px"
                  height="522px"
                  alt="image"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>

              <Box
                display="flex"
                align-items="center"
                justify-content="center"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                right="0px"
                bottom="auto"
                left="auto"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.4s ease-in-out 0s"
                background="rgba(0, 0, 0, 0.12)"
                hover-background="rgba(0, 0, 0, 0.12)"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="7208202840"
                  className="hoverText"
                  onClick={() => isSideBarOpen("7208202840")}
                >
                  {data ? data["7208202840"] : "ARCHITECT DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="7665751330"
                className="hoverText"
                onClick={() => isSideBarOpen("7665751330")}
              >
                {data ? data["7665751330"] : "Renovation & Restoration"}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            lg-align-items="flex-start"
            sm-width="100%"
            grid-column="1 / span 2"
            lg-grid-column="1 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
      
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
            >
              <Box
                id="9806527550"
                className="hoverText"
                width="100%"
                onClick={() => isSideBarOpen("9806527550")}
              >
                <Image
                  src={
                    data
                      ? data["9806527550"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13:37:14.301Z"
                  }
                  border-radius={0}
                  max-width="100%"
                  width="100%"
                  object-fit="cover"
                  lg-max-height="392px"
                  object-position="50% 50%"
                  sm-max-height="213px"
                  height="522px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_4%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.301Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                display="flex"
                align-items="center"
                justify-content="center"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                right="0px"
                bottom="auto"
                left="auto"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.4s ease-in-out 0s"
                background="rgba(0, 0, 0, 0.12)"
                hover-background="rgba(0, 0, 0, 0.12)"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="4490169654"
                  className="hoverText"
                  onClick={() => isSideBarOpen("4490169654")}
                >
                  {data ? data["4490169654"] : "INTERIOR DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="9187811140"
                className="hoverText"
                onClick={() => isSideBarOpen("9187811140")}
              >
                {data ? data["9187811140"] : "Master Planning"}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="3 / span 1"
            lg-grid-column="2 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
            href="architect-portolio"
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
              id="8751884359"
              className="hoverText"
              onClick={() => isSideBarOpen("8751884359")}
            >
              <Image
                src={
                  data
                    ? data["8751884359"]
                    : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/03%20villa%20layout.png?v=2023-09-13T13:44:35.997Z"
                }
                border-radius={0}
                max-width="100%"
                width="100%"
                object-fit="cover"
                lg-max-height="392px"
                object-position="50% 50%"
                sm-max-height="213px"
                height="522px"
                alt="image"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="9971611063"
                  className="hoverText"
                  onClick={() => isSideBarOpen("9971611063")}
                >
                  {data ? data["9971611063"] : "ARCHITECT DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="4483825217"
                className="hoverText"
                onClick={() => isSideBarOpen("4483825217")}
              >
                {data ? data["4483825217"] : "Sustainable Design"}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="1 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
         
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
              id="3281530683"
              className="hoverText"
              onClick={() => isSideBarOpen("3281530683")}
            >
              <Image
                src={
                  data
                    ? data["3281530683"]
                    : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13:55:34.469Z"
                }
                border-radius={0}
                max-width="100%"
                width="100%"
                object-fit="cover"
                lg-max-height="392px"
                object-position="50% 50%"
                sm-max-height="213px"
                height="522px"
                alt="image"
                background="rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1551038247-3d9af20df552?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) 0% 0% /auto repeat scroll padding-box"
                // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/FINAL%2022.png?v=2023-09-13T13%3A55%3A34.469Z&quality=85&w=3200 3200w"
                sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
              />
              <Box
                display="flex"
                align-items="center"
                justify-content="center"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                right="0px"
                bottom="auto"
                left="auto"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.4s ease-in-out 0s"
                background="rgba(0, 0, 0, 0.12)"
                hover-background="rgba(0, 0, 0, 0.12)"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="8049812521"
                  className="hoverText"
                  onClick={() => isSideBarOpen("8049812521")}
                >
                  {data ? data["8049812521"] : "INTERIOR DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="7678828354"
                className="hoverText"
                onClick={() => isSideBarOpen("7678828354")}
              >
                {data ? data["7678828354"] : "Commercial Design"}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            display="flex"
            md-flex-direction="column"
            md-align-items="center"
            md-justify-content="center"
            flex-wrap="wrap"
            sm-width="100%"
            grid-column="2 / span 2"
            lg-grid-column="2 / span 1"
            sm-align-self="auto"
            sm-grid-column="auto"
            justify-content="flex-start"
            href="architect-portolio"
          >
            <Box
              width="100%"
              align-items="flex-start"
              display="flex"
              justify-content="flex-start"
              md-width="100%"
              md-margin="0px 0px 0px 0px"
              lg-width="100%"
              position="relative"
            >
              <Box
                id="5613777042"
                className="hoverText"
                width="100%"
                onClick={() => isSideBarOpen("5613777042")}
              >
                <Image
                  src={
                    data
                      ? data["5613777042"]
                      : "https://uploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13:37:14.292Z"
                  }
                  border-radius={0}
                  max-width="100%"
                  width="100%"
                  object-fit="cover"
                  lg-max-height="392px"
                  object-position="50% 50%"
                  sm-max-height="213px"
                  height="522px"
                  alt="image"
                  // srcSet="https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/64f08d0c588b2d00195cbf7a/images/001_3%20-%20Photo.png?v=2023-09-13T13%3A37%3A14.292Z&quality=85&w=3200 3200w"
                  sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
              </Box>
              <Box
                display="flex"
                align-items="center"
                justify-content="center"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                right="0px"
                bottom="auto"
                left="auto"
                opacity="0"
                hover-opacity="1"
                transition="opacity 0.4s ease-in-out 0s"
                background="rgba(0, 0, 0, 0.12)"
                hover-background="rgba(0, 0, 0, 0.12)"
              />
            </Box>
            <Box
              width="100%"
              padding="16px 12px 16px 12px"
              display="flex"
              align-items="flex-start"
              justify-content="flex-start"
              md-width="100%"
              md-border-width="0px"
              flex-direction="column"
            >
              <Box
                display="flex"
                width="100%"
                justify-content="space-between"
                align-items="center"
                margin="0px 0px 6px 0px"
              >
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                  id="8264260973"
                  className="hoverText"
                  onClick={() => isSideBarOpen("8264260973")}
                >
                  {data ? data["8264260973"] : "ARCHITECT DESIGN"}
                </Text>
                <Text
                  font="--subheader2"
                  color="--darkGrey"
                  text-align="center"
                  sm-font="--subheader1"
                  margin="0px 0px 0px 0px"
                >
                  🡭
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--descirption3"
                color="--grey"
                opacity=".5"
                id="9065326673"
                className="hoverText"
                onClick={() => isSideBarOpen("9065326673")}
              >
                {data
                  ? data["9065326673"]
                  : "Transforming Visions into Reality"}
              </Text>
            </Box>
          </LinkBox>
        </Box>
      </Section> */}
      <Section
        padding="80px 0 80px 0"
        sm-padding="40px 0 40px 0"
        md-padding="40px 0 80px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="column"
          flex-wrap="wrap"
        />

        <Box
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          width="100%"
        >
          {categoryData.result && categoryData.result.length > 0
            ? categoryData.result.map((item, i) => (
                <Box className="item" key={i}>
                  <LinkBox
                    display="flex"
                    md-flex-direction="column"
                    md-align-items="center"
                    md-justify-content="center"
                    flex-wrap="wrap"
                    sm-width="100%"
                    grid-column="1 / span 1"
                    sm-align-self="auto"
                    sm-grid-column="auto"
                    justify-content="flex-start"
                    href="category-album"
                    onClick={() => dispatch(selectedPortfolio(item))}
                  >
                    <Box
                      width="100%"
                      align-items="flex-start"
                      display="flex"
                      justify-content="flex-start"
                      md-width="100%"
                      md-margin="0px 0px 0px 0px"
                      lg-width="100%"
                      position="relative"
                      // onClick={AlbumData}
                    >
                      <Box width="100%">
                        <Image
                          src={
                            item.image
                              ? process.env.REACT_APP_STORAGE_URL_2 + item.image
                              : "https://uploads.quarkly.io/64d4bf2f6efcea001ae4fe4f/images/Group%205565.png?v=2023-08-31T07:43:44.271Z"
                          }
                          border-radius={0}
                          max-width="100%"
                          width="100%"
                          object-fit="cover"
                          lg-max-height="392px"
                          object-position="50% 50%"
                          sm-max-height="213px"
                          height="522px"
                          alt="image"
                          sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                      </Box>
                      <Box
                        display="flex"
                        align-items="center"
                        justify-content="center"
                        position="absolute"
                        width="100%"
                        height="100%"
                        top="0px"
                        right="0px"
                        bottom="auto"
                        left="auto"
                        opacity="0"
                        hover-opacity="1"
                        transition="opacity 0.4s ease-in-out 0s"
                        background="rgba(0, 0, 0, 0.12)"
                        hover-background="rgba(0, 0, 0, 0.12)"
                      />
                    </Box>
                    <Box
                      width="100%"
                      padding="16px 12px 16px 12px"
                      display="flex"
                      align-items="flex-start"
                      justify-content="flex-start"
                      md-width="100%"
                      md-border-width="0px"
                      flex-direction="column"
                    >
                      <Box
                        display="flex"
                        width="100%"
                        justify-content="space-between"
                        align-items="center"
                        margin="0px 0px 6px 0px"
                      >
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--subheader1"
                          margin="0px 0px 0px 0px"
                          className="uppercase"
                        >
                          {item.name}
                        </Text>
                        <Text
                          font="--subheader2"
                          color="--darkGrey"
                          text-align="center"
                          sm-font="--subheader1"
                          margin="0px 0px 0px 0px"
                        >
                          🡭
                        </Text>
                      </Box>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--descirption3"
                        color="--grey"
                        opacity=".5"
                        className="uppercase"
                      >
                        {item.description}
                      </Text>
                    </Box>
                  </LinkBox>
                </Box>
              ))
            : "no-data"}
        </Box>
        <Box display="flex" justify-content="end" margin="9px">
          <PaginationControl
            className="page-link1"
            page={activePage}
            between={4}
            ellipsis={1}
            next={true}
            last={true}
            total={categoryData.totalCount}
            limit={10}
            changePage={(page) => {
              handlePage(page);
            }}
          />
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64d4bf2f6efcea001ae4fe4d"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
